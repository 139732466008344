import React from "react";
import Button from "@mui/material/Button";

const CustomButton = ({ title, ...props }) => {
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      sx={{
        my: 1,
        background: "#FF9933 ",
        mt: 2,
        color: "white",
        letterSpacing: 2.5,
        fontWeight: "1000",
        fontSize: 15,
        fontFamily: "open sans",
        textTransform: "capitalize",
        "&:hover": {
          background: "#FF9933 ",
          color: "#000",
        },
      }}
      {...props}
    >
      {title}
    </Button>
  );
};

export default CustomButton;
