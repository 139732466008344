import {
  Box,
  Button,
  Container,
  Divider,
  Pagination,
  Paper,
  Typography,
  Grid,
} from '@mui/material';
import AgriCard from '../../Utils/AgriCard';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Ack from '../../../Components/Ack';
import axios from 'axios';
import AgriSkelton from '../../../Pages/AllSkeleton/AgriSkelton';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../Store/auth';

function Products() {
  // user data
  const { token, userID } = useSelector((state) => state.loging);

  // pagination data
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // product data
  const [products, setProducts] = useState([]);
  const [isEmpty, setEmpty] = useState(true);

  // loading handler
  const [isLoaded, setLoaded] = useState(false);

  // ack popup
  const [open, setOpen] = useState(false);

  // delete handler
  const [id, setID] = useState();
  const [index, setIndex] = useState();
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleChange = (event, value) => {
    setPage(value);
  };

  // useEffect hook
  useEffect(() => {
    const fetchProducts = async () => {
      setLoaded(true);
      setEmpty(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APIURL}api/products?pagination=${page}&owner=${userID}`,
          {
            headers: { Authorization: 'Agriuservalidation ' + token },
          }
        );

        if (response.data.data && response.data.data.length > 0) {
          setProducts(response.data.data);
          setEmpty(false);
          const pcount = Math.ceil(+response.data.cdata / 6);
          setCount(pcount);
        } else {
          setProducts([]);
          setEmpty(true);
        }
      } catch (error) {
        setEmpty(true);
        handleFetchError(error);
      } finally {
        setLoaded(false);
      }
    };

    fetchProducts();
  }, [page, token, userID]);

  const handleFetchError = (error) => {
    if (error.response) {
      const statusCode = error.response.status;
      if (statusCode === 400) {
        setSeverity('error');
        setMessage('Token expired, please login again.');
        setOpenSnackbar(true);
        setTimeout(() => {
          dispatch(logout());
          navigate('/login');
        }, 7000);
      } else {
        setSeverity('error');
        setMessage('An error occurred. Please try again later.');
        setOpenSnackbar(true);
      }
    } else if (error.request) {
      setSeverity('error');
      setMessage(
        'No response from server. Please check your network connection.'
      );
      setOpenSnackbar(true);
    } else {
      setSeverity('error');
      setMessage(`Error: ${error.message}`);
      setOpenSnackbar(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    setOpen(false);
    axios
      .delete(`${process.env.REACT_APP_APIURL}api/products?_id=${id}`, {
        headers: { Authorization: 'Agriuservalidation ' + token },
      })
      .then((res) => {
        setProducts((prev) => prev.filter((_, idx) => idx !== index));
      })
      .catch(handleFetchError);
  };

  const clickDelete = (id, index) => {
    setOpen(true);
    setID(id);
    setIndex(index);
  };

  return (
    <>
      <Ack
        title={'Alert'}
        open={open}
        handleClose={handleClose}
        msg='Are you sure to delete'
        handleYes={handleYes}
      />
      <Box
        component={Paper}
        p={2}
        elevation={0}
        square
        minHeight={'100vh'}
        sx={{ borderRadius: '10px' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h5' sx={{ color: '#232F3E' }}>
            Your Products
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            href='/product/add'
            variant='contained'
            disableElevation
            sx={{
              bgcolor: '#232F3E',
              color: '#FFFFFF',
              '&:hover': { bgcolor: '#FF9933', color: '#FFFFFF' },
            }}
          >
            Add Product
          </Button>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Container maxWidth='xll'>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems={'space-between'}
            spacing={1}
            minHeight='50vh'
          >
            {isEmpty && !isLoaded && (
              <Box mt={7} component={Typography} sx={{ textAlign: 'center' }}>
                No products to show
              </Box>
            )}
            {!isEmpty &&
              !isLoaded &&
              products.map((row, index) => (
                <AgriCard
                  clickDelete={clickDelete}
                  key={row._id}
                  data={row}
                  index={index}
                />
              ))}
            {isLoaded && (
              <>
                <AgriSkelton />
                <AgriSkelton />
                <AgriSkelton />
                <AgriSkelton />
                <AgriSkelton />
                <AgriSkelton />
              </>
            )}
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'center' }} mt={6}>
            <Box sx={{ flexGrow: 1 }} />
            <Pagination count={count} color='primary' onChange={handleChange} />
            <Box sx={{ flexGrow: 1 }} />
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Products;
