// import React, { useState } from "react";
// import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
// import ListItemButton from "@mui/material/ListItemButton";
// import TextField from "@mui/material/TextField";
// import InputAdornment from "@mui/material/InputAdornment";
// import SearchIcon from "@mui/icons-material/Search";
// import Grid from "@mui/material/Grid";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
// import Typography from "@mui/material/Typography";
// function LocationViewDialogBox({ open, onClose, onSelectCity, onSelectArea }) {
//   // const { open, onClose } = props;
//   const [selectedCity, setSelectedCity] = useState("");
//   const [selectedArea, setSelectedArea] = useState("");
//   const [areaSearch, setAreaSearch] = useState("");
//   const [citySearch, setCitySearch] = useState("");

//   const cities = [
//     "Colombo",
//     "Kandy",
//     "Galle",
//     "Matara",
//     "Batticaloa",
//     "Jaffna",
//     "Gampaha",
//     "Anuradhapura",
//     "Trincomalee",
//     "Badulla",
//     "Ratnapura",
//     "Puttalam",
//     "Ampara",
//     "Matale",
//     "Vavuniya",
//     "Mulaittivu",
//     "Kalutara",
//     "Mannar",
//     "Kurunegala",
//     "Polonnaruwa",
//     "Nuwara Eliya",
//     "Kilinochchi",
//     "Kegalle",
//     "Moneragala",
//     "Hambantota",
//   ];

//   const areas = {
//     Colombo: [
//       "Maharagama",
//       "Nugegoda",
//       "Dehiwala",
//       "Mount Lavinia",
//       "Kotte",
//       "Rajagiriya",
//       "Battaramulla",
//       "Malabe",
//       "Kolonnawa",
//       "Piliyandala",
//       "Homagama",
//       "Kaduwela",
//       "Moratuwa",
//       "Panadura",
//       "Kesbewa",
//       "Ratmalana",
//       "Pannipitiya",
//       "Kelaniya",
//       "Wattala",
//       "Ja-Ela",
//       "Negombo",
//       "Sri Jayawardenepura Kotte",
//       "Boralesgamuwa",
//     ],
//     Kandy: [
//       "Peradeniya",
//       "Katugastota",
//       "Gampola",
//       "Akurana",
//       "Matale",
//       "Digana",
//       "Kundasale",
//       "Pilimatalawa",
//       "Wattegama",
//       "Teldeniya",
//       "Theldeniya",
//       "Galagedara",
//       "Nawalapitiya",
//       "Madawala Bazaar",
//       "Pussellawa",
//       "Kadugannawa",
//       "Uduwela",
//     ],
//     Galle: [
//       "Hikkaduwa",
//       "Unawatuna",
//       "Ambalangoda",
//       "Weligama",
//       "Matara",
//       "Habaraduwa",
//       "Ahangama",
//       "Baddegama",
//       "Elpitiya",
//       "Imaduwa",
//       "Karapitiya",
//       "Koggala",
//       "Thalpe",
//     ],
//     Matara: [
//       "Weligama",
//       "Mirissa",
//       "Dickwella",
//       "Dondra",
//       "Matara Town",
//       "Hakmana",
//       "Akuressa",
//       "Deniyaya",
//       "Kamburugamuwa",
//       "Walahanduwa",
//       "Weligama Town",
//       "Kotapola",
//     ],
//     Batticaloa: [
//       "Kattankudy",
//       "Valaichchenai",
//       "Kaluvanchikudy",
//       "Eravur",
//       "Batticaloa Town",
//       "Chenkalady",
//       "Vakarai",
//       "Kaluvankerni",
//       "Navatkudah",
//       "Kokkadichcholai",
//     ],
//     Jaffna: [
//       "Nallur",
//       "Chavakachcheri",
//       "Point Pedro",
//       "Kankesanthurai",
//       "Jaffna Town",
//       "Ponanai",
//       "Chankanai",
//       "Velanai",
//       "Kaithady",
//       "Kilinochchi",
//       "Vavuniya",
//       "Mallakam",
//       "Tellippalai",
//       "Maruthanamadam",
//       "Kopay",
//     ],
//     Gampaha: [
//       "Negombo",
//       "Katunayake",
//       "Seeduwa",
//       "Wattala",
//       "Gampaha Town",
//       "Minuwangoda",
//       "Veyangoda",
//       "Mirigama",
//       "Dompe",
//       "Ja-Ela",
//       "Kelaniya",
//       "Kadawatha",
//       "Mahara",
//       "Attanagalla",
//     ],
//     Anuradhapura: [
//       "Medawachchiya",
//       "Mihintale",
//       "Tambuttegama",
//       "Galnewa",
//       "Anuradhapura Town",
//       "Kekirawa",
//       "Rambewa",
//       "Kahatagasdigiliya",
//       "Horowpathana",
//       "Talawa",
//     ],
//     Trincomalee: [
//       "Kinniya",
//       "Kuchchaveli",
//       "Nilaveli",
//       "Seruvila",
//       "Trincomalee Town",
//       "Kantalai",
//       "Kuchchiramkulam",
//       "Gomarankadawala",
//       "Sampur",
//     ],
//     Badulla: [
//       "Bandarawela",
//       "Haputale",
//       "Ella",
//       "Mahiyangana",
//       "Badulla Town",
//       "Welimada",
//       "Passara",
//       "Rideemaliyadda",
//       "Welimada Town",
//       "Haldummulla",
//     ],
//     Ratnapura: [
//       "Balangoda",
//       "Embilipitiya",
//       "Kuruwita",
//       "Eheliyagoda",
//       "Ratnapura Town",
//       "Pelmadulla",
//       "Elapatha",
//       "Ratnapura",
//       "Kahawatta",
//       "Godakawela",
//     ],
//     Puttalam: [
//       "Kalpitiya",
//       "Nattandiya",
//       "Chilaw",
//       "Wennappuwa",
//       "Puttalam Town",
//       "Anamaduwa",
//       "Marawila",
//       "Wariyapola",
//       "Madampe",
//     ],
//     Ampara: [
//       "Akkaraipattu",
//       "Pottuvil",
//       "Kalmunai",
//       "Sainthamaruthu",
//       "Ampara Town",
//       "Kalmunai Town",
//       "Sammanthurai",
//       "Kondavattavan",
//       "Kalmunai North",
//       "Kalmunai South",
//     ],
//     Matale: [
//       "Dambulla",
//       "Sigiriya",
//       "Galewela",
//       "Ukuwela",
//       "Matale Town",
//       "Naula",
//       "Rattota",
//       "Gammaduwa",
//       "Ambanganga Korale",
//       "Rattota",
//     ],
//     Vavuniya: [
//       "Vavuniya North",
//       "Vavuniya South",
//       "Nedunkerni",
//       "Mullaitivu",
//       "Vavuniya Town",
//       "Mankulam",
//       "Vavuniya South",
//       "Nedunkerni",
//       "Mankulam",
//     ],
//     Mulaittivu: [
//       "Mullaitivu Town",
//       "Puthukudiyiruppu",
//       "Oddusuddan",
//       "Mankulam",
//       "Mullaitivu",
//       "Puthukudiyiruppu",
//       "Oddusuddan",
//       "Mankulam",
//     ],
//     Kalutara: [
//       "Panadura",
//       "Horana",
//       "Matugama",
//       "Kalutara South",
//       "Kalutara Town",
//       "Beruwala",
//       "Aluthgama",
//       "Wadduwa",
//     ],
//     Mannar: [
//       "Mannar Town",
//       "Nanattan",
//       "Vankalai",
//       "Thalaimannar",
//       "Mannar",
//       "Nanattan",
//       "Vankalai",
//       "Thalaimannar",
//     ],
//     Kurunegala: [
//       "Kuliyapitiya",
//       "Narammala",
//       "Polgahawela",
//       "Kurunegala Town",
//       "Giriulla",
//       "Alawwa",
//       "Rasnayakapura",
//       "Pannala",
//       "Mallawapitiya",
//     ],
//     Polonnaruwa: [
//       "Medirigiriya",
//       "Hingurakgoda",
//       "Polonnaruwa Town",
//       "Minneriya",
//       "Polonnaruwa",
//       "Elahera",
//       "Dimbulagala",
//       "Lankapura",
//     ],
//     NuwaraEliya: [
//       "Nuwara Eliya Town",
//       "Hatton",
//       "Nuwara Eliya North",
//       "Nuwara Eliya South",
//       "Hatton Town",
//       "Ginigathhena",
//       "Nuwara Eliya North",
//       "Nuwara Eliya South",
//     ],
//     Kilinochchi: [
//       "Kilinochchi Town",
//       "Pooneryn",
//       "Paranthan",
//       "Mankulam",
//       "Kilinochchi",
//       "Pooneryn",
//       "Paranthan",
//       "Mankulam",
//     ],
//     Kegalle: [
//       "Kegalle Town",
//       "Mawanella",
//       "Dehiowita",
//       "Rambukkana",
//       "Kegalle",
//       "Mawanella",
//       "Dehiowita",
//       "Rambukkana",
//     ],
//     Moneragala: [
//       "Wellawaya",
//       "Bibile",
//       "Buttala",
//       "Kataragama",
//       "Moneragala",
//       "Wellawaya",
//       "Bibile",
//       "Buttala",
//       "Kataragama",
//     ],
//     Hambantota: [
//       "Tangalle",
//       "Ambalantota",
//       "Hambantota Town",
//       "Tissamaharama",
//       "Hambantota",
//       "Tangalle",
//       "Ambalantota",
//       "Tissamaharama",
//     ],
//     // ... add more if needed
//   };

//   // const handleCitySelect = (city) => {
//   //   setSelectedCity(city);
//   //   onSelectLocation(city); // Call the callback function provided by parent component
//   // };

//   const handleAreaSelect = (area) => {
//     setSelectedArea(area);
//     onSelectArea(area); // Call the callback function provided by the parent component to handle area selection
//     onClose(); // Optionally close the dialog after selection
//   };

//   // Filtered areas based on search
//   const filteredAreas = selectedCity
//     ? areas[selectedCity].filter((area) =>
//         area.toLowerCase().includes(areaSearch.toLowerCase())
//       )
//     : [];
//   // Filtered citys based on search
//   const filteredCitys = cities
//     ? cities.filter((area) =>
//         area.toLowerCase().includes(citySearch.toLowerCase())
//       )
//     : [];

//   return (
//     <Dialog
//       onClose={onClose}
//       aria-labelledby="location-selection"
//       open={open}
//       fullWidth
//       maxWidth="md"
//     >
//       <DialogTitle id="location-selection-title">Select Location</DialogTitle>
//       <Grid container spacing={2}>
//         <Grid item xs={6} sm={6} md={6} lg={6}>
//           <Typography
//             variant="subtitle1"
//             sx={{ display: "block", justifyContent: "center", margin: "10px" }}
//           >
//             Select City
//           </Typography>
//           <List>
//             <ListItem>
//               {" "}
//               <ListItemButton
//                 // onClick={() => setSelectedArea("All of Sri Lanka")

//                 // }
//                 onClick={() => handleAreaSelect("All of Sri Lanka")}
//               >
//                 <ListItemText
//                   primary={"All of Sri Lanka"}
//                   sx={{ color: "#232F3E" }}
//                 />
//               </ListItemButton>
//             </ListItem>
//             <ListItem>
//               <TextField
//                 fullWidth
//                 value={citySearch}
//                 onChange={(event) => setCitySearch(event.target.value)}
//                 InputProps={{
//                   startAdornment: (
//                     <InputAdornment position="start">
//                       <SearchIcon />
//                     </InputAdornment>
//                   ),
//                 }}
//                 placeholder={`Search city...`}
//               />
//             </ListItem>
//             {cities &&
//               filteredCitys.map((city) => (
//                 <ListItem key={city} disablePadding>
//                   <ListItemButton onClick={() => setSelectedCity(city)}>
//                     <ListItemText primary={city} />
//                     <ChevronRightOutlinedIcon
//                       sx={{
//                         marginLeft: "auto",
//                         color: selectedCity === city ? "gray" : "black",
//                       }}
//                     />
//                     {/* { && <ChevronRightIcon  />} */}
//                   </ListItemButton>
//                 </ListItem>
//               ))}
//           </List>
//         </Grid>
//         <Grid item xs={6} sm={6} md={6} lg={6} sx={{ textAlign: "center" }}>
//           <Typography
//             variant="subtitle1"
//             sx={{ display: "block", justifyContent: "center", margin: "10px" }}
//           >
//             Select Areas
//           </Typography>
//           <List>
//             <ListItem>
//               <TextField
//                 fullWidth
//                 value={areaSearch}
//                 onChange={(event) => setAreaSearch(event.target.value)}
//                 InputProps={{
//                   startAdornment: (
//                     <InputAdornment position="start">
//                       <SearchIcon />
//                     </InputAdornment>
//                   ),
//                 }}
//                 placeholder={`Search in ${selectedCity}`}
//               />
//             </ListItem>
//             {selectedCity &&
//               filteredAreas.map((area) => (
//                 <ListItem key={area} disablePadding>
//                   <ListItemButton onClick={() => handleAreaSelect(area)}>
//                     <ListItemText
//                       primary={area}
//                       sx={{
//                         color: selectedArea === area ? "#232F3E" : "black",
//                       }}
//                     />
//                   </ListItemButton>
//                 </ListItem>
//               ))}
//           </List>
//         </Grid>
//       </Grid>
//     </Dialog>
//   );
// }

// export default LocationViewDialogBox;

import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import Typography from "@mui/material/Typography";

function LocationViewDialogBox({ open, onClose, onSelectCity, onSelectArea }) {
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [areaSearch, setAreaSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");

  const cities = [
    "All of Sri Lanka",
    "Colombo",
    "Kandy",
    "Galle",
    "Matara",
    "Batticaloa",
    "Jaffna",
    "Gampaha",
    "Anuradhapura",
    "Trincomalee",
    "Badulla",
    "Ratnapura",
    "Puttalam",
    "Ampara",
    "Matale",
    "Vavuniya",
    "Mulaittivu",
    "Kalutara",
    "Mannar",
    "Kurunegala",
    "Polonnaruwa",
    "Nuwara Eliya",
    "Kilinochchi",
    "Kegalle",
    "Moneragala",
    "Hambantota",
  ];

  const areas = {
    "All of Sri Lanka": ["All of Sri Lanka"],
    Colombo: ["All Colombo"],
    Kandy: ["All Kandy"],
    Galle: ["All Galle"],
    Matara: ["All Matara"],
    Batticaloa: ["All Batticaloa"],
    Jaffna: ["All Jaffna"],
    Gampaha: ["All Gampaha"],
    Anuradhapura: ["All Anuradhapura"],
    Trincomalee: ["All Trincomalee"],
    Badulla: ["All Badulla"],
    Ratnapura: ["All Ratnapura"],
    Puttalam: ["All Puttalam"],
    Ampara: ["All Ampara"],
    Matale: ["All Matale"],
    Vavuniya: ["All Vavuniya"],
    Mulaittivu: ["All Mullaitivu"],
    Kalutara: ["All Kalutara"],
    Mannar: ["All Mannar"],
    Kurunegala: ["All Kurunegala"],
    Polonnaruwa: ["All Polonnaruwa"],
    NuwaraEliya: ["All Nuwara Eliya"],
    Kilinochchi: ["All Kilinochchi"],
    Kegalle: ["All Kegalle"],
    Moneragala: ["All Moneragala"],
    Hambantota: ["All Hambantota"],
    // ... add more if needed
  };

  const handleCitySelect = (city) => {
    setSelectedCity(city);
    onSelectCity(city);
    onClose();
  };

  const handleAreaSelect = (area) => {
    setSelectedArea(area);
    onSelectArea(area);
    onClose();
  };

  const filteredAreas = selectedCity
    ? areas[selectedCity].filter((area) =>
        area.toLowerCase().includes(areaSearch.toLowerCase())
      )
    : [];

  const filteredCities = cities.filter((city) =>
    city.toLowerCase().includes(citySearch.toLowerCase())
  );

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="location-selection"
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="location-selection-title">Select Location</DialogTitle>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" sx={{ margin: "10px" }}>
            Select City
          </Typography>
          <List>
            <ListItem>
              <TextField
                fullWidth
                value={citySearch}
                onChange={(e) => setCitySearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search city..."
              />
            </ListItem>
            {filteredCities.map((city) => (
              <ListItem key={city} disablePadding>
                <ListItemButton onClick={() => handleCitySelect(city)}>
                  <ListItemText primary={city} />
                  <ChevronRightOutlinedIcon sx={{ marginLeft: "auto" }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" sx={{ margin: "10px" }}>
            Select Area
          </Typography>
          <List>
            <ListItem>
              <TextField
                fullWidth
                value={areaSearch}
                onChange={(e) => setAreaSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder={`Search in ${selectedCity}`}
              />
            </ListItem>
            {filteredAreas.map((area) => (
              <ListItem key={area} disablePadding>
                <ListItemButton onClick={() => handleAreaSelect(area)}>
                  <ListItemText primary={area} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default LocationViewDialogBox;
