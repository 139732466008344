import { configureStore } from '@reduxjs/toolkit';
import authStore from './auth';
// import themeStore from "./theme";
import vendorIDReducer from './VendorIDSlice'; // The vendor ID slice

import favoritesReducer from './favoritesSlice';
import cartReducer from './cartSlice'; // Updated import
import stockReducer from './stockSlice';
import quantityReducer from './quantitySlice';
import cartAndAmountSlice from './cartAndAmountSlice';
const store = configureStore({
  reducer: {
    loging: authStore.reducer,
    // mode: themeStore.reducer,
    cart: cartReducer,
    favorites: favoritesReducer,
    vendorID: vendorIDReducer,
    stock: stockReducer,
    quantities: quantityReducer, // Add this line
    cartAndAmount: cartAndAmountSlice,
  },
});

export default store;
