import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Header from '../../Components/Header';
import AgriCart from '../Utils/AgriCart';
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import axios from 'axios';
import CartSkelton from '../Utils/CartSkelton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AgriCard from '../Utils/AgriCard';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import CardMedia from '@mui/material/CardMedia';
import image from '../../Assets/Rancrsip-Cassava-Chips-Hot-Spicy-100g-300x300.jpg';
import DownloadIcon from '@mui/icons-material/Download';
import jsPDF from 'jspdf';
import CustomSnackbar from '../Adminpages/Components/CustomSnackbar';
import RecommendedProductSkeleton from '../AllSkeleton/RecommendedProductSkeleton';
import logo1 from '../../Assets/logo1.png';
import { logout } from '../../Store/auth';
import { removeItemFromCart } from '../../Store/cartSlice';
import EmptyUI from '../EmptyUI/EmptyUI';

function Cart(props) {
  const formatAddress = (address) => {
    return `${address.address}, ${address.city}, ${address.province}, ${address.country},  ${address.postalcode}`;
  };
  const theme = useTheme();
  const { userID, token } = useSelector((state) => state.loging);
  const [cart, setCart] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [total, setTotal] = useState(0);
  const [cartObj, setCartObj] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackba$everity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [emptyrecommendedProducts, setEmptyrecommendedProducts] =
    useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const [loadingRecommendedProducts, setLoadingRecommendedProducts] =
    useState(true);

  const [displayedRecommendations, setDisplayedRecommendations] = useState([]); // New state for managing displayed recommendations
  const [showMoreVisible, setShowMoreVisible] = useState(false);
  const [customdetails, setCustomdetails] = useState({});
  const [cartDetails, setCartDetails] = useState([]);
  const [severity, setseverity] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  // State to manage "Show More" button visibility

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APIURL}users/?ID=${userID}`, {
        headers: { Authorization: 'Agriuservalidation ' + token },
      })
      .then((res) => {
        console.log(res.data);
        setCustomdetails(res.data);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              console.log('Authentication failed. Logging out.');
              dispatch(logout());
              navigate('/login');
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other erro$
            setseverity('error');
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setseverity('error');
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
    if (cart.length > 0) {
      const cartProductIds = cart.map((item) => item._id);

      axios
        .post(
          `${process.env.REACT_APP_APIURL}api/RecommendationProductsFromCarts`,
          { productIds: cartProductIds },
          {
            headers: { Authorization: 'Agriuservalidation ' + token },
          }
        )
        .then((res) => {
          setRecommendedProducts(res.data.recommendations);
          setLoadingRecommendedProducts(true);
          if (res.data.recommendations.length > 5) {
            setShowMoreVisible(true);
            setDisplayedRecommendations(res.data.recommendations.slice(0, 5));
          } else {
            setDisplayedRecommendations(res.data.recommendations);
            setShowMoreVisible(false);
          }
          setEmptyrecommendedProducts(res.data.recommendations.length === 0);
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            const statusCode = error.response.status;
            console.log(statusCode, error.response.data);
            if (statusCode === 400) {
              setseverity('error');
              setMessage('Token expired, please login again.');
              setOpenSnackbar(true);

              // Delay logout and redirection to ensure the user sees the toast message
              setTimeout(() => {
                console.log('Authentication failed. Logging out.');
                dispatch(logout());
                navigate('/login');
              }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
            } else {
              // Handle other erro$
              setseverity('error');
              setMessage('An error occurred. Please try again later.');
              setOpenSnackbar(true);
            }
          } else if (error.request) {
            // The request was made but no response was received
            setseverity('error');
            setMessage(
              'No response from server. Please check your network connection.'
            );
            setOpenSnackbar(true);
          } else {
            setseverity('error');
            // Something happened in setting up the request that triggered an Error
            setMessage(`Error: ${error.message}`);
            setOpenSnackbar(true);
          }
        });
    } else {
      setEmptyrecommendedProducts(true);
    }
    calculateTotalItems();
  }, [cart, token]);

  const calculateTotalItems = () => {
    setTotalItems(cart.length ? cart.length : 0);
  };

  const handleShowMore = () => {
    setDisplayedRecommendations(recommendedProducts);
    setShowMoreVisible(false);
  };

  const hexToRgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
  };

  // Define colo$
  const primaryColor = hexToRgb('#232F3E'); // Dark blue
  const accentColor = hexToRgb('#FF9933'); // Orange

  const fetchAndGeneratePDFs = (cartProductIds, token) => {
    console.log('CartProductIds:', cartProductIds);
    console.log('cartobject :', cartObj);

    axios
      .get(
        `${process.env.REACT_APP_APIURL}api/products/cartdetails/pdf?cartItemIds=${cartProductIds}`,
        {
          headers: { Authorization: 'Agriuservalidation ' + token },
        }
      )
      .then((res) => {
        console.log(res.data);
        const groupedByVendor = res.data; // Assuming this is an array of vendor groups
        const primaryColor = [35, 47, 62]; // Converted primary color to RGB
        const secondaryColor = [255, 153, 51]; // Converted secondary color to RGB
        groupedByVendor.forEach((vendorGroup) => {
          const doc = new jsPDF();
          const logoBase64 =
            'iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAADzElEQVR4nO2bWahOURTHtyFjhkQRL8gUIcP1oDx5MSshT5LhFZfrGlJK5IXwoo/wIENeFIoyPOHF+IBkfLiRwnUNyZSflrMP2+4737373POd8f7q9N3bWX3nW/9z9tp7rbO2UgbAUKAWGK6KCPAQj/fAWFU0gMf84xnQRxUJYBzQZIhwThUNYD7wyxBhiSoawH5DgBdAJ1UkgJ7Aa0OElapoABsMAe6pooH3FHwxRCjktHjaEKBeFQ1gqSHAfREh48d6p1UuMIL80eAiQDvgE/nio+swuE2+uOgqwEnyxRZXAbaRL6a5CrAk5IXqgO7WgioOu0p8Bbq6CjAh5MX+5A/yGbNdJa45OS8APazssEUogzjtmmGnCgPwMicCzAgrwNUcCPAT6BVWgAM5EOBOKOcFYK3r1ZRBnHYV2KvCAszMgQALWiPA0BwIMKA1AnTQi4isCvAotPM+wAOXK6ZMgEOqtQBnMizAnCgE2JVRAd4AXaIQYFlGc4FNrXZeAKY6XrheZ28bY7YzeeCc/QUB9CVbNEVeygfekg3eyRMbqfMCcIP0cx0YrKoBcJT0cg1YLJXsqjgvSERN2kvgM3BXv7XaASwCBqk4ABbE5OQ33aFyCdgHrAKmA0OA9rE4Ww5gTIROfjecLOlpbo52soNKI0BnXVlx4ZXl5EJgNNBRZRHgeRknG3UQMp2cGNkiJE0AFwzHt0Syzs4SwHZDgLOqaADjrcd/dVXn3jQCnLJEuAnsTqjpoS721l6gN3CL9PA0VgEEnZru14uW4gngA/TTa/BNumoU97EVGPb3B7XRRhuJgRcY5+ldJ/I+cS7QLYLvHaUzwnr9OVKlCbx2ujprrwHG7pN1YRZL4miFV/NXpI+xOh45IPk5cKIFU9VxFxGAKQGC2uLWqCTBu/P/zck6Kyzpv01qHRq1JY32+SF5B7BHdrHo/32ke6VH9T0NHvPmXTokNQOrfnDYumPNxgSdZZoV3knW+Rqdgkf78sMVHeR8npjOWyJI5cdntmN36vIAm5WGzU2VBHjd1z4HKtiVXIaB1Z/cP8Cmf+j+36jAi+4+pQp2Bw27tY4ClG1sAAYaNh9UEuAVMX2eBgyBLlYZbZbjEFgRYLMqDUOgmw5sPoetICjOHzHON7akTghstoJgTZkp0gyCG1VS8P8wQAe8kn7s7QLqGodp0GzOlGnvvJ4Gz1uV6YbEpkFjFSiLnOY45rgQqrGernLI+ckqafBEqA34wfKorgm5FJYtO5cDnL+Uul3ueDFBAqMkQnLMjuLdgBZC5n1JhuQzMsd/A5/dLf6ysARUAAAAAElFTkSuQmCC';

          // Adding the company logo to the document
          // The x and y paramete$ are the coordinates for the image's top-left corner.
          // The width and height paramete$ scale the image. Adjust as necessary for your layout.
          doc.addImage(logoBase64, 'PNG', 10, 10, 30, 30);

          // Initial Setup with Background Color for Title
          doc.setFillColor(240, 240, 240); // Light grey background
          doc.rect(0, 15, 210, 10, 'F'); // Background for title
          doc.setTextColor(primaryColor[0], primaryColor[1], primaryColor[2]);
          doc.setFontSize(20);
          doc.setFont('helvetica', 'bold');
          doc.text('Checkout Details Invoice', 105, 20, null, null, 'center');

          // Company Details Section
          doc.setFillColor(255, 235, 235); // Light red background for header
          doc.rect(0, 35, 210, 6, 'F'); // Background for Company Details
          doc.setFontSize(10);
          doc.setTextColor(
            secondaryColor[0],
            secondaryColor[1],
            secondaryColor[2]
          );
          doc.text('Company Details:', 10, 40);
          doc.setTextColor(primaryColor[0], primaryColor[1], primaryColor[2]);
          doc.setFont('helvetica', 'normal');
          doc.text('Name: Your Company Name', 10, 45);
          doc.text('Address: Your Company Address', 10, 50);

          // Customer Details Section
          doc.setFillColor(235, 245, 255); // Light blue background for header
          doc.rect(0, 55, 210, 6, 'F'); // Background for Customer Details
          doc.setTextColor(
            secondaryColor[0],
            secondaryColor[1],
            secondaryColor[2]
          );
          doc.text('Customer Details:', 10, 60);
          doc.setTextColor(primaryColor[0], primaryColor[1], primaryColor[2]);
          doc.text(`Name: ${customdetails.username}`, 10, 65);
          doc.text(`Address: ${formatAddress(customdetails.address)}`, 10, 70);
          doc.text(`Mobile Number: ${customdetails.mobile_number}`, 10, 75);

          // Vendor Details Section
          doc.setFillColor(235, 255, 235); // Light green background for header
          doc.rect(0, 80, 210, 6, 'F'); // Background for Vendor Details
          doc.setTextColor(
            secondaryColor[0],
            secondaryColor[1],
            secondaryColor[2]
          );
          doc.text('Vendor Details:', 10, 85);
          doc.setTextColor(primaryColor[0], primaryColor[1], primaryColor[2]);
          doc.text(`Name: ${vendorGroup.vendorDetails.username}`, 10, 90);
          doc.text(
            `Address: ${formatAddress(vendorGroup.vendorDetails.address)}`,
            10,
            95
          );
          doc.text(
            `Mobile Number: ${vendorGroup.vendorDetails.mobile_number}`,
            10,
            100
          );

          let y = 120;
          let grandTotal = 0;

          doc.setFontSize(10);
          doc.setFont('helvetica', 'bold');
          doc.setFillColor(220, 220, 220); // Grey background for table heade$
          doc.rect(0, y - 4, 210, 6, 'F'); // Background for heade$
          doc.setTextColor(
            secondaryColor[0],
            secondaryColor[1],
            secondaryColor[2]
          );
          doc.text('No.', 10, y);
          doc.text('Product Name', 20, y);
          doc.text('Price', 130, y, null, null, 'right');
          doc.text('Quantity', 150, y, null, null, 'right');
          doc.text('Total', 180, y, null, null, 'right');
          y += 10;

          doc.setFont('helvetica', 'normal');
          doc.setTextColor(primaryColor[0], primaryColor[1], primaryColor[2]);

          vendorGroup.products.forEach((item, index) => {
            const quantity = cartObj[item._id]; // Use cartObj to get the quantity
            console.log(cartObj[item.id]);
            console.log(quantity);
            if (y >= 280) {
              doc.addPage();
              y = 20; // Reset Y position
            }

            const totalPrice = quantity * item.price;
            grandTotal += totalPrice; // Add to grand total

            const truncatedTitle =
              item.title.length > 40
                ? item.title.substring(0, 37) + '...'
                : item.title;

            doc.text(`${index + 1}.`, 10, y);
            doc.text(truncatedTitle, 20, y);
            doc.text(`Rs ${item.price.toFixed(2)}`, 130, y, null, null, 'right');
            doc.text(`${quantity}`, 150, y, null, null, 'right');
            doc.text(`Rs ${totalPrice.toFixed(2)}`, 180, y, null, null, 'right');
            y += 6; // Increment Y for next item
          });

          // Display Grand Total
          if (y >= 280) {
            doc.addPage();
            y = 20; // Reset Y position at top of new page
          }
          doc.setFillColor(220, 220, 220); // Grey background for grand total
          doc.rect(0, y, 210, 6, 'F'); // Background for grand total row
          doc.setFont('helvetica', 'bold');
          doc.setTextColor(
            secondaryColor[0],
            secondaryColor[1],
            secondaryColor[2]
          );
          doc.text('Grand Total', 20, y + 5);
          doc.setTextColor(primaryColor[0], primaryColor[1], primaryColor[2]);
          doc.text(
            `$${grandTotal.toFixed(2)}`,
            180,
            y + 5,
            null,
            null,
            'right'
          );

          // Save PDF with filename including vendor name and date-time
          const currentDate = new Date();
          const dateTimeString =
            currentDate.toISOString().split('T')[0] +
            '_' +
            currentDate.toTimeString().split(' ')[0].replace(/:/g, '-');
          doc.save(
            `invoice_${vendorGroup.vendorDetails.username}_${dateTimeString}.pdf`
          );
        });
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              console.log('Authentication failed. Logging out.');
              dispatch(logout());
              navigate('/login');
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other erro$
            setseverity('error');
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setseverity('error');
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  };
  const downloadPdf = () => {
    if (cart.length > 0) {
      const cartProductIds = cart.map((item) => item._id);

      fetchAndGeneratePDFs(cartProductIds, token);
    }
  };

  const checkOutHandler = () => {
    console.log(cartObj);
    axios
      .post(
        `${process.env.REACT_APP_APIURL}api/orders`,
        {
          products: cartObj,
          user_id: userID,
          total,
        },
        {
          headers: { Authorization: 'Agriuservalidation ' + token },
        }
      )
      .then((res) => {
        navigate(`/checkout/${res.data._id}`, { replace: true });
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              console.log('Authentication failed. Logging out.');
              dispatch(logout());
              navigate('/login');
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other erro$
            setseverity('error');
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setseverity('error');
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  };

  const quantityHandler = (operation, price, id, amount, stock) => {
    if (operation === 'inc' && amount < stock) {
      setTotal((pre) => {
        let val = pre * 100 + price * 100;
        return val / 100;
      });
      setCartObj((pre) => ({ ...pre, [id]: amount + 1 }));
    } else if (operation === 'dec' && amount > 1) {
      setTotal((pre) => (pre * 100 - price * 100) / 100);
      setCartObj((pre) => ({ ...pre, [id]: amount - 1 }));
    }
  };

  const calTotal = () => {
    let total = 0;
    cart.forEach((row) => {
      total += row.price;
    });
    return total;
  };

  const removeCart = (index, id) => {
    axios
      .delete(
        `${process.env.REACT_APP_APIURL}users/carts?id=${userID}&pid=${id}`,
        {
          headers: { Authorization: 'Agriuservalidation ' + token },
        }
      )
      .then((res) => {
        dispatch(removeItemFromCart(id));
        setTotal((pre) => {
          let data = +pre * 100;
          let minus = +cartObj[id];
          data -= minus * (+cart[index].price * 100);
          return data / 100;
        });
        setCartObj((pre) => {
          console.log(id);
          const { [id]: dummy, ...objectWithoutDeleted } = pre;
          return objectWithoutDeleted;
        });
        setCart((pre) => {
          const array = [...pre];
          array.splice(index, 1);
          return array;
        });
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              console.log('Authentication failed. Logging out.');
              dispatch(logout());
              navigate('/login');
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other erro$
            setseverity('error');
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setseverity('error');
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APIURL}users/carts?_id=${userID}`, {
        headers: { Authorization: 'Agriuservalidation ' + token },
      })
      .then((res) => {
        const cartItems = res.data;
        axios
          .get(
            `${process.env.REACT_APP_APIURL}api/products?pagination=1&favList=${
              cartItems.length !== 0
                ? cartItems.map((item) => item.pid._id).join(',')
                : ['1', '2']
            }`,
            {
              headers: { Authorization: 'Agriuservalidation ' + token },
            }
          )
          .then((res) => {
            if (res.data) {
              const products = res.data.data;
              const cartWithQty = products.map((product) => {
                const cartItem = cartItems.find(
                  (item) => item.pid._id === product._id
                );
                return { ...product, qty: cartItem ? cartItem.qty : 1 };
              });

              setCart(cartWithQty);
              setLoaded(true);
              setCartObj((pre) => {
                let obj = {};
                for (let i = 0; i < cartWithQty.length; i++) {
                  obj = { ...obj, [cartWithQty[i]._id]: cartWithQty[i].qty };
                }
                return obj;
              });
              setTotal((pre) => {
                let data = 0;
                for (let i = 0; i < cartWithQty.length; i++) {
                  data += cartWithQty[i].price * cartWithQty[i].qty * 100;
                }
                return data / 100;
              });
            }
          })
          .catch((error) => {
            handleError(error);
          });
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  const handleError = (error) => {
    setLoaded(true);
    if (error.response) {
      const statusCode = error.response.status;
      if (statusCode === 400) {
        setseverity('error');
        setMessage('Token expired, please login again.');
        setOpenSnackbar(true);
        setTimeout(() => {
          dispatch(logout());
          navigate('/login');
        }, 7000);
      } else {
        // setseverity('error');
        // setMessage('An error occurred. Please try again later.');
        // setOpenSnackbar(true);
      }
    } else if (error.request) {
      setseverity('error');
      setMessage(
        'No response from server. Please check your network connection.'
      );
      setOpenSnackbar(true);
    } else {
      setseverity('error');
      setMessage(`Error: ${error.message}`);
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      <Header token={token} />
      {/* <Header  /> */}
      <CustomSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        severity={severity}
        message={message}
      />
      <Container
        maxWidth='xl'
        sx={{ minHeight: '100vh', py: isMobile ? 2 : 4 }}
      >
        <Grid container spacing={isMobile ? 2 : 4}>
          {/* Left side - Cart */}
          <Grid item xs={12} sm={9}>
            <Paper elevation={3} sx={{ borderRadius: 4 }}>
              <Box p={isMobile ? 2 : 4}>
                <Typography
                  variant={isMobile ? 'h5' : 'h4'}
                  color='#232F3E'
                  mb={3}
                >
                  Your Cart Total Items: {totalItems}
                </Typography>

                <Divider />
                <Box mt={3}>
                  {isLoaded ? (
                    cart.map((row, index) => (
                      <AgriCart
                        quantity={row.qty}
                        index={index}
                        removeCart={removeCart}
                        key={row._id}
                        data={row}
                        quantityHandler={quantityHandler}
                        stock={row.stock}
                      />
                    ))
                  ) : (
                    <>
                      <CartSkelton />
                      <CartSkelton />
                    </>
                  )}
                  {isLoaded && cart.length === 0 && (
                    <Box mt={3}>
                      {/* <Typography
                        variant='subtitle1'
                        color='#232F3E'
                        textAlign='center'
                      >
                        Cart is Empty
                      </Typography> */}
                      <EmptyUI />
                    </Box>
                  )}
                </Box>
                <Divider />

                <Box
                  sx={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    borderTop: '1px solid #e0e0e0',
                    p: 2,
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row', // Change flex direction based on screen size
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: isMobile ? 2 : 0, // Add gap only for mobile view
                  }}
                >
                  <Typography
                    variant={isMobile ? 'subtitle1' : 'h5'}
                    color='#232F3E'
                  >
                    Total: Rs {total}
                  </Typography>
                  <Button
                    onClick={downloadPdf}
                    variant='contained'
                    startIcon={<DownloadIcon />}
                    sx={{
                      backgroundColor: '#FF9933',
                      color: '#fff',
                      textTransform: 'none',
                      fontSize: isMobile ? 'small' : 'inherit', // Adjust font size for mobile
                      '&:hover': { backgroundColor: '#FF9933', color: '#000' },
                      width: isMobile ? '100%' : 'auto', // Full width on mobile
                    }}
                  >
                    Download as PDF
                  </Button>
                  <Button
                    onClick={checkOutHandler}
                    disabled={!calTotal()}
                    variant='contained'
                    color='primary'
                    startIcon={<ShoppingCartCheckoutOutlinedIcon />}
                    sx={{
                      backgroundColor: '#FF9933',
                      color: '#fff',
                      textTransform: 'none',
                      fontSize: isMobile ? 'small' : 'inherit', // Adjust font size for mobile
                      '&:hover': { backgroundColor: '#FF9933', color: '#000' },
                      width: isMobile ? '100%' : 'auto', // Full width on mobile
                    }}
                  >
                    CheckOut
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Paper elevation={3} sx={{ borderRadius: 4 }}>
              <Box p={isMobile ? 2 : 4}>
                <Typography
                  variant={isMobile ? 'h6' : 'h4'}
                  color='#232F3E'
                  mb={3}
                >
                  Recommended Products
                </Typography>
                <Divider />
                <Box mt={3} display='flex' flexDirection='column'>
                  {emptyrecommendedProducts && (
                    <Typography
                      sx={{
                        color: '#232F3E',
                        fontWeight: 'bold',
                        fontSize: isMobile ? '14px' : '16px',
                        mb: 2,
                        mt: 2,
                        textAlign: 'center',
                      }}
                    >
                      Recommendations are not available for this product
                    </Typography>
                  )}
                  {!emptyrecommendedProducts && loadingRecommendedProducts && (
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: isMobile
                          ? 'repeat(2, 1fr)'
                          : '1fr',
                        gap: 2,
                      }}
                    >
                      {displayedRecommendations.map((product, index) => (
                        <Card
                          key={index}
                          sx={{
                            mb: 1,
                            borderRadius: 2,
                            boxShadow: 2,
                            // p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <CardMedia
                            image={product.images[0]}
                            alt={product.title}
                            component='img'
                            loading='lazy'
                            sx={{
                              width: '100%',
                              height: 150,
                              objectFit: 'contain',
                              mb: 2,
                            }}
                          />
                          <CardContent>
                            <Typography
                              mb={1}
                              sx={{
                                color: '#232F3E',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                              }}
                            >
                              {product.title}
                            </Typography>
                            <Typography
                              variant='subtitle1'
                              mb={2}
                              sx={{ color: '#232F3E', fontWeight: 'bold' }}
                            >
                              Rs {product.price}
                            </Typography>
                            <Button
                              onClick={() =>
                                navigate(`/product/view/${product._id}`)
                              }
                              variant='contained'
                              color='primary'
                              // endIcon={<KeyboardDoubleArrowRightIcon />}
                              sx={{
                                backgroundColor: '#FF9933',
                                color: '#fff',
                                textTransform: 'none',
                                '&:hover': {
                                  backgroundColor: '#FF9933',
                                  color: '#000',
                                },
                                width: '100%',
                              }}
                            >
                              More details
                            </Button>
                          </CardContent>
                        </Card>
                      ))}
                    </Box>
                  )}
                  {!loadingRecommendedProducts && (
                    <>
                      <RecommendedProductSkeleton />
                      <RecommendedProductSkeleton />
                      <RecommendedProductSkeleton />
                    </>
                  )}
                </Box>
              </Box>

              {showMoreVisible && (
                <Button
                  fullWidth
                  onClick={handleShowMore}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    paddingBottom: '10px',
                    marginBottom: '10px',
                    textTransform: 'none',
                    color: '#fff',
                    backgroundColor: '#FF9933',
                    '&:hover': {
                      backgroundColor: '#FF9933',
                      color: '#000',
                    },
                  }}
                >
                  Show More
                </Button>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Cart;
