import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Divider,
  Skeleton,
  IconButton,
  Rating,
  LinearProgress,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Header from "../../Components/Header";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import { red } from "@mui/material/colors";
import Recommendations from "../Category/Recommendations";
import { Carousel } from "react-responsive-carousel";
import CustomSnackbar from "../Adminpages/Components/CustomSnackbar";
import { addFavorite, removeFavorite } from "../../Store/favoritesSlice";
import { addItemToCart } from "../../Store/cartSlice";
import { setQuantity } from "../../Store/quantitySlice"; // Add this line
import StarRateIcon from "@mui/icons-material/StarRate";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Footer from "../../Components/Footer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

function HoverRating({
  rating,
  setRating,
  isRatingDisabled,
  handleRatingChange,
}) {
  const [hover, setHover] = useState(-1);

  return (
    <Box
      sx={{
        width: 200,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Rating
        name="hover-feedback"
        value={rating}
        precision={0.5}
        getLabelText={getLabelText}
        onChange={handleRatingChange}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        emptyIcon={
          <StarRateIcon style={{ opacity: 0.55 }} fontSize="inherit" />
        }
        disabled={isRatingDisabled}
      />
      {rating !== null && (
        <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : rating]}</Box>
      )}
    </Box>
  );
}

function ProductView() {
  const [quantity, setQuantityState] = useState(1); // Rename to avoid conflict
  const [isRatingDisabled, setIsRatingDisabled] = useState(false);
  const dispatch = useDispatch();
  const { token, userID } = useSelector((state) => state.loging);
  const [product, setProduct] = useState({});
  const [isLoaded, setLoaded] = useState(false);
  const [isFavorite, setFavorite] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarOpenAddToCart, setSnackbarOpenAddToCart] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [rating, setRating] = useState(2.5);
  const [totalRating, setTotalRating] = useState(0);
  const [ratingCount, setRatingCount] = useState(0);
  const [ratingsDistribution, setRatingsDistribution] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });
  const [recommendationRate, setRecommendationRate] = useState(0);

  const [ratingHistory, setRatingHistory] = useState([]);
  const extractRatingHistory = (ratings, userId) => {
    const ratingHistory = ratings.flatMap((rating) =>
      rating.userId === userId
        ? rating.ratingHistory
          ? rating.ratingHistory.map((history) => ({
              rating: history.rating,
              date: history.date,
            }))
          : [{ rating: rating.rating, date: new Date().toISOString() }]
        : []
    );
    return ratingHistory || [];
  };
  const [visibleEntries, setVisibleEntries] = useState(3);

  const handleShowMore = () => {
    setVisibleEntries((prevVisibleEntries) => prevVisibleEntries + 3);
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APIURL}api/products/${id}`, {
        headers: { Authorization: "Agriuservalidation " + token },
      })
      .then((res) => {
        if (res.data) {
          setProduct(res.data);
          setLoaded(true);
          setRating(res.data.rating || 2.5);
          setTotalRating(res.data.totalRating || 0);
          setRatingCount(res.data.ratingCount || 0);
          setRatingsDistribution(
            res.data.ratingsDistribution || { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
          );
          setRecommendationRate(
            calculateRecommendationRate(res.data.ratingsDistribution)
          );
          const ratingHistoryData = extractRatingHistory(
            res.data.ratings,
            userID
          );
          setRatingHistory(ratingHistoryData);
        }
      })
      .catch((error) => {
        console.error("Error fetching product", error);
        navigate("/", { replace: true });
      });

    axios
      .get(`${process.env.REACT_APP_APIURL}users/favorites?_id=${userID}`, {
        headers: { Authorization: "Agriuservalidation " + token },
      })
      .then((res) => {
        if (res.data) {
          setFavorite(res.data.includes(id));
        }
      })
      .catch((error) => {
        console.error("Error fetching favorites", error);
      });
  }, [id, token, userID, navigate]);

  const calculateRecommendationRate = (distribution) => {
    const totalReviews = Object.values(distribution).reduce(
      (acc, curr) => acc + curr,
      0
    );
    const positiveReviews = distribution[4] + distribution[5];
    return totalReviews
      ? Math.round((positiveReviews / totalReviews) * 100)
      : 0;
  };

  const handleFavoriteToggle = () => {
    axios
      .put(
        `${process.env.REACT_APP_APIURL}users/favorites`,
        { _id: userID, pid: id, val: !isFavorite },
        {
          headers: { Authorization: "Agriuservalidation " + token },
        }
      )
      .then(() => {
        setFavorite(!isFavorite);
        isFavorite ? dispatch(removeFavorite(id)) : dispatch(addFavorite(id));
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("Error updating favorite status", error);
      });
  };

  const handleAddToCart = () => {
    axios
      .put(
        `${process.env.REACT_APP_APIURL}users/carts`,
        { pid: id, _id: userID, set: true, qty: quantity },
        {
          headers: { Authorization: "Agriuservalidation " + token },
        }
      )
      .then(() => {
        dispatch(addItemToCart(id));
        dispatch(setQuantity({ productId: id, quantity: quantity })); // Update quantity in store
        setSnackbarOpenAddToCart(true);
      })
      .catch((error) => {
        console.error("Error adding to cart", error);
      });
  };

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
    // setIsRatingDisabled(true);
    axios
      .post(
        `${process.env.REACT_APP_APIURL}api/products/customerrating`,
        {
          productID: id,
          rating: newValue,
          userID: userID,
        },
        {
          headers: { Authorization: "Agriuservalidation " + token },
        }
      )
      .then((response) => {
        setRating(response.data.rating);
        setRatingsDistribution(response.data.ratingsDistribution);
        setRatingHistory(response.data.userRatingHistory);
        setTotalRating(response.data.totalRating);
        setRatingCount(response.data.ratingCount);
        console.log("Rating updated successfully");
        setIsRatingDisabled(true);
      })
      .catch((error) => {
        console.error("Error updating rating", error);
      });
  };

  const handleIncrement = () => {
    setQuantityState((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    setQuantityState((prevQuantity) =>
      prevQuantity > 1 ? prevQuantity - 1 : 1
    );
  };

  return (
    <>
      <Header token={token} />
      <Box pt={1} minHeight="100vh">
        <Container maxWidth="lg">
          {isLoaded ? (
            <Grid container spacing={1} pt={3}>
              <Grid item xs={12} md={6}>
                <Carousel>
                  {product.images.map((img, index) => (
                    <div key={index}>
                      <img
                        src={img}
                        alt={`Product image ${index + 1}`}
                        style={{ maxWidth: "400px", maxHeight: "400px" }}
                      />
                    </div>
                  ))}
                </Carousel>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h5" color="#232F3E">
                    {product.title}
                  </Typography>
                  <IconButton
                    onClick={handleFavoriteToggle}
                    sx={{ color: red[800] }}
                  >
                    {isFavorite ? (
                      <FavoriteOutlinedIcon />
                    ) : (
                      <FavoriteBorderOutlinedIcon />
                    )}
                  </IconButton>
                </Box>
                <Typography variant="h3" color="#232F3E" mt={2} mb={2}>
                  Rs. {product.price}
                </Typography>

                <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                  <HoverRating
                    rating={rating}
                    setRating={setRating}
                    // isRatingDisabled={isRatingDisabled}
                    handleRatingChange={handleRatingChange}
                  />
                  <Typography variant="caption" color="#999" sx={{ ml: 1 }}>
                    ({ratingCount} reviews, Average Rating: {rating})
                  </Typography>
                </Box>

                <Typography variant="subtitle1" gutterBottom color="#232F3E">
                  Category: {product.category.categoryName}
                </Typography>
                <Typography variant="subtitle1" gutterBottom color="#232F3E">
                  Sub Category: {product.sub_category.sub_categoryName}
                </Typography>
                <Typography variant="body1" gutterBottom color="#232F3E">
                  {product.description}
                </Typography>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="start"
                  mb={2}
                  py={3}
                >
                  <Button
                    onClick={handleDecrement}
                    variant="outlined"
                    size="small"
                    sx={{
                      minWidth: "32px",
                      height: "32px",
                      mx: 1,
                      color: "#555",
                      borderColor: "#FF9933",
                      ":hover": {
                        borderColor: "#FF9933",
                        backgroundColor: "#FF9933",
                        color: "#fff",
                      },
                    }}
                  >
                    <RemoveIcon />
                  </Button>
                  <Typography sx={{ width: 40, textAlign: "center" }}>
                    {quantity}
                  </Typography>
                  <Button
                    onClick={handleIncrement}
                    variant="outlined"
                    size="small"
                    sx={{
                      minWidth: "32px",
                      height: "32px",
                      mx: 1,
                      color: "#555",
                      borderColor: "#FF9933",
                      ":hover": {
                        borderColor: "#FF9933",
                        backgroundColor: "#FF9933",
                        color: "#fff",
                      },
                    }}
                  >
                    <AddIcon />
                  </Button>
                  <Button
                    onClick={handleAddToCart}
                    variant="contained"
                    size="large"
                    sx={{
                      px: 10,
                      fontSize: 12,
                      color: "#fff",
                      backgroundColor: "#FF9933",
                      ":hover": { backgroundColor: "#FF9933", color: "#000" },
                      ml: 2, // Added margin left for spacing
                    }}
                  >
                    Add to Cart
                  </Button>
                </Box>
                <Grid item xs={12} md={6} lg={12}>
                  <Typography variant="h6" gutterBottom>
                    Review Highlights
                  </Typography>
                  <Box display="flex" alignItems="center" mt={1}>
                    <Rating
                      value={rating}
                      readOnly
                      precision={0.1}
                      sx={{ fontSize: "2rem", mr: 1 }}
                    />
                    <Typography variant="h5">
                      {rating ? rating.toFixed(1) : "0.0"} stars
                    </Typography>
                  </Box>
                  <Typography variant="body2">
                    {recommendationRate}% of reviewers would recommend this
                    product to a friend.
                  </Typography>
                  <Typography variant="body2">
                    Rating Distribution ({ratingCount} reviews)
                  </Typography>

                  <Box>
                    {[5, 4, 3, 2, 1].map((star) => (
                      <Box
                        key={star}
                        display="flex"
                        alignItems="center"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mb: 1, // Added some bottom margin for spacing
                        }}
                      >
                        <Box display="flex" alignItems="center">
                          {Array.from({ length: 5 }, (_, index) => (
                            <StarRateIcon
                              key={index}
                              sx={{
                                fontSize: "1.5rem",
                                color: index < star ? "#FF9933" : "#E0E0E0",
                              }}
                            />
                          ))}
                        </Box>
                        <LinearProgress
                          variant="determinate"
                          color="success"
                          value={
                            ratingCount > 0
                              ? (ratingsDistribution[star] / ratingCount) * 100
                              : 0
                          }
                          sx={{
                            flexGrow: 1,
                            mx: 2,
                            height: 8,
                            backgroundColor: "#E5E5E5",
                            borderRadius: 5,
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#FF9933",
                            },
                          }}
                        />
                        <Typography
                          variant="body2"
                          sx={{ minWidth: "40px", textAlign: "right" }}
                        >
                          {ratingsDistribution[star]}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Grid item xs={12} md={6} lg={12}>
                    <Typography variant="h6" gutterBottom>
                      Your Rating History
                    </Typography>
                    <Box>
                      {ratingHistory.length > 0 ? (
                        ratingHistory
                          .slice(0, visibleEntries)
                          .map((entry, index) => (
                            <Box
                              key={index}
                              display="flex"
                              alignItems="center"
                              mb={1}
                            >
                              <Typography variant="body2" sx={{ mr: 2 }}>
                                {new Date(entry.date).toLocaleString()}
                              </Typography>
                              <Rating
                                value={entry.rating}
                                readOnly
                                precision={0.5}
                              />
                            </Box>
                          ))
                      ) : (
                        <Typography variant="body2">
                          You have not rated this product yet.
                        </Typography>
                      )}
                      {ratingHistory.length > visibleEntries && (
                        <Button
                          onClick={handleShowMore}
                          sx={{ mt: 2 }}
                          endIcon={<ExpandMoreIcon />}
                        >
                          Show More
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Skeleton variant="rectangular" height={300} />
          )}
        </Container>
        <Box container spacing={2} sx={{ m: 5 }}>
          <Recommendations productID={product._id} />
        </Box>
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        severity="success"
        message={`Product ${
          isFavorite ? "added to" : "removed from"
        } favorites`}
      />
      <CustomSnackbar
        open={snackbarOpenAddToCart}
        onClose={() => setSnackbarOpenAddToCart(false)}
        severity="success"
        message="Product added to cart"
      />
      <Footer />
    </>
  );
}

export default ProductView;
