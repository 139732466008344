import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  useTheme,
  Grid,
  Typography,
  Box,
  Pagination,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SimpleAgriCard from '../Utils/SimpleAgriCard';

function CategorySidebar({
  categoriess,
  emptycategory,
  Productscategory,
  currentPagecategory,
  totalPagescategory,
  handlePageChange,
  handleSubcategoryHover,
  handleCategoryHover,
  hoveredSubcategory,
  selectedCategory,
}) {
  const theme = useTheme();

  return (
    <Box
      component={'div'}
      onMouseLeave={() =>
        handleSubcategoryHover(null) || handleCategoryHover(null)
      }
      style={{
        marginTop: '23px',
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        overflowY: 'auto', // Enable vertical scrolling for the entire sidebar
        maxHeight: '90vh', // Adjust maxHeight to fit your layout
      }}
    >
      <List
        style={{
          backgroundColor: '#FFFFFF',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.2) inset',
          borderRadius: '4px',
          maxHeight: '400px',
          overflowY: 'auto', // Enable vertical scrolling for the categories list
        }}
      >
        {categoriess.map((category) => (
          <ListItem
            button
            key={category._id}
            onMouseEnter={() => handleCategoryHover(category)}
            onClick={() => handleCategoryHover(category)}
          >
            <ListItemAvatar>
              <Avatar
                src={category.categoryimages}
                sx={{ height: '30px', width: '30px', objectFit: 'contain' }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={category.categoryname}
              primaryTypographyProps={{ variant: 'body1', fontSize: '11px' }}
            />
            <ChevronRightIcon />
          </ListItem>
        ))}
      </List>

      {selectedCategory && (
        <List
          style={{
            width: 250,
            borderRadius: '4px',
            padding: theme.spacing(2),
            borderLeft: `1px solid ${theme.palette.divider}`,
            backgroundColor: '#FFFFFF',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.2) inset',
            maxHeight: '400px',
            overflowY: 'auto', // Enable vertical scrolling for the subcategories list
          }}
        >
          {selectedCategory.subcategories.length === 0 && (
            <Typography
              variant='subtitle1'
              sx={{
                padding: theme.spacing(2),
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              No subcategories found.
            </Typography>
          )}
          {selectedCategory.subcategories.map((subcategory) => (
            <ListItem
              button
              key={subcategory._id}
              onClick={() => handleSubcategoryHover(subcategory)}
              onMouseEnter={() => handleSubcategoryHover(subcategory)}
            >
              <ListItemText
                primary={subcategory.subcategoryname}
                primaryTypographyProps={{
                  variant: 'body1',
                  fontSize: '11px',
                }}
              />
              <ChevronRightIcon />
            </ListItem>
          ))}
        </List>
      )}

      {hoveredSubcategory && (
        <Box
          sx={{
            flexGrow: 1,
            padding: 2,
            borderLeft: `1px solid ${theme.palette.divider}`,
            minWidth: 1000,
            maxHeight: 900,
            borderRadius: '4px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.2) inset',
            overflowY: 'auto', // Enable vertical scrolling for the products section
          }}
        >
          <Grid
            container
            spacing={2}
            style={{
              padding: theme.spacing(2),
              backgroundColor: '#FFFFFF',
              borderLeft: `1px solid ${theme.palette.divider}`,
              maxHeight: '850px', // Adjust maxHeight to fit your layout
              overflowY: 'auto', // Enable vertical scrolling for the product grid
            }}
          >
            {Productscategory.map((product) => (
              <SimpleAgriCard key={product._id} product={product} />
            ))}
            {emptycategory && (
              <Typography
                variant='subtitle1'
                sx={{ textAlign: 'center', padding: theme.spacing(2) }}
              >
                No products found in this subcategory.
              </Typography>
            )}
          </Grid>

          {totalPagescategory > 1 && (
            <Pagination
              style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#FFFFFF',
                marginBottom: theme.spacing(2),
                paddingBottom: theme.spacing(2),
              }}
              count={totalPagescategory}
              page={currentPagecategory}
              onChange={handlePageChange}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

export default CategorySidebar;
