import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Grid,
  Avatar,
  Tooltip,
  Zoom,
  Skeleton,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import CustomSnackbar from "./Components/CustomSnackbar";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { logout } from "../../Store/auth";

function CategoryManagement() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const [isEmpty, setEmpty] = useState(false);
  const [isEmptysub, setEmptysub] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [isLoadedsub, setLoadedsub] = useState(false);

  const [openSuccessEditSnackbar, setOpenSuccessEditSnackbar] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [previewUrl, setPreviewUrl] = useState("");
  const { token, userID } = useSelector((state) => state.loging);
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setid] = useState("");
  const [currentCategory, setCurrentCategory] = useState({
    categoryname: "",
    categoryimages: "",
  });
  const [image, setImage] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackba$everity, setSnackba$everity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const [subcategoryDialogOpen, setSubcategoryDialogOpen] = useState(false);
  const [subcategories, setSubcategories] = useState([]);

  const [currentSubcategory, setCurrentSubcategory] = useState({
    subcategoryname: "",
  });
  const [editModeSubcategory, setEditModeSubcategory] = useState(false);
  const [parentCategoryId, setParentCategoryId] = useState(null);

  const [currentPagesubcategory, setCurrentPagesubcategory] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPagessubcategory, setTotalPagessubcategory] = useState(0);

  const [newSubcategoryName, setNewSubcategoryName] = useState("");
  const [addSubcategoryDialogOpen, setAddSubcategoryDialogOpen] =
    useState(false);

  const [categoriesid, setCategoriesid] = useState("");
  const [severity, setseverity] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbarerror, setOpenSnackbarerror] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_APIURL}api/Category?page=${currentPage}&limit=4&search=${searchQuery}`,
        {
          headers: { Authorization: "Agriuservalidation " + token },
        }
      )
      .then((res) => {
        if (res.data) {
          setCategories(res.data.data);
          setTotalPages(res.data.totalPages);
          setLoaded(true);
          setEmpty(false);
        }

        if (res.data.data.length === 0) {
          setEmpty(true);
          setSnackba$everity("warning");
          setSnackbarMessage("No categories found.");
          setSnackbarOpen(true);
          return;
        }
      })
      .catch((error) => {
        handleApiError(error);
      });
  }, [currentPage, searchQuery]);

  const handleOpen = (
    category = { categoryname: "", categoryimages: "" },
    isEdit = false,
    id
  ) => {
    setCurrentCategory(category);
    setIsEditMode(isEdit);
    setPreviewUrl(category.categoryimages);
    setOpen(true);
    setid(id ? id : "");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > 1024 * 1024) {
        setSnackba$everity("error");
        setSnackbarMessage("Image size should be less than 1 MB.");
        setSnackbarOpen(true);
        return;
      }
      if (!file.type.startsWith("image/")) {
        setSnackba$everity("error");
        setSnackbarMessage("Only image files are allowed.");
        setSnackbarOpen(true);
        return;
      }
      setImage(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleDelete = () => {
    const _id = id;

    if (!_id) {
      setSnackba$everity("error");
      setSnackbarMessage("Error: Missing category ID.");
      setSnackbarOpen(true);
      return;
    }

    axios
      .delete(
        `${process.env.REACT_APP_APIURL}api/Category?_id=${_id}&userID=${userID}`,
        {
          headers: { Authorization: "Agriuservalidation " + token },
        }
      )
      .then((response) => {
        if (response.data && response.data.deleted) {
          const updatedCategories = categories.filter(
            (category) => category._id !== _id
          );
          setCategories(updatedCategories);
          setSnackba$everity("success");
          setSnackbarMessage("Category deleted successfully!");
          setSnackbarOpen(true);
          setConfirmDialogOpen(false); // Close the confirmation dialog
        } else {
          setSnackba$everity("error");
          setSnackbarMessage(
            "Error: Failed to delete category. Please try again."
          );
          setSnackbarOpen(true);
        }
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const handleDeleteAction = (vendorId) => {
    setid(vendorId);
    setConfirmDialogOpen(true);
  };

  const handleSave = () => {
    const data = new FormData();
    if (isEditMode) {
      data.append("categoryname", currentCategory.categoryname);
      data.append("user_id", userID);
      data.append("image", image);
      data.append("_id", id ? id : "");
      axios
        .put(`${process.env.REACT_APP_APIURL}api/Category`, data, {
          headers: { Authorization: "Agriuservalidation " + token },
        })
        .then((res) => {
          const updatedCategory = res.data.data;
          const updatedCategories = categories.map((category) =>
            category._id === id ? updatedCategory : category
          );

          setCategories(updatedCategories);
          handleClose();
          setSnackba$everity("success");
          setSnackbarMessage("Category updated successfully!");
          setSnackbarOpen(true);
        })
        .catch((error) => {
          handleApiError(error);
        });
    } else {
      data.append("categoryname", currentCategory.categoryname);
      data.append("user_id", userID);
      data.append("image", image);
      axios
        .post(`${process.env.REACT_APP_APIURL}api/Category`, data, {
          headers: { Authorization: "Agriuservalidation " + token },
        })
        .then((res) => {
          setCategories([...categories, res.data.data]);
          handleClose();
          setSnackba$everity("success");
          setSnackbarMessage("Category added successfully!");
          setSnackbarOpen(true);
        })
        .catch((error) => {
          handleApiError(error);
        });
    }
  };

  const handleSubcategoryDialogOpen = (
    categoryId,
    page = currentPagesubcategory
  ) => {
    setCategoriesid(categoryId);
    axios
      .get(
        `${process.env.REACT_APP_APIURL}api/sub/Category/${categoryId}?page=${page}&limit=3}`,
        {
          headers: { Authorization: "Agriuservalidation " + token },
        }
      )
      .then((response) => {
        setSubcategories(response.data.subcategories);
        setTotalPagessubcategory(response.data.totalPages);
        setCurrentPagesubcategory(page); // Update the current page state
        setSubcategoryDialogOpen(true);
        setEmptysub(false);
        setLoadedsub(true);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const editSubcategory = (subcategory) => {
    setCurrentSubcategory(subcategory);
    setEditModeSubcategory(true);
  };

  const deleteSubcategory = (subcategoryId) => {
    axios
      .delete(`${process.env.REACT_APP_APIURL}api/sub/Category/`, {
        params: {
          subcategoryId: subcategoryId || currentSubcategory._id,
          categoryId: categoriesid,
        },
        headers: { Authorization: "Agriuservalidation " + token },
      })
      .then((data) => {
        if (data.data.deleteed == true) {
          const updatedSubcategories = subcategories.filter(
            (sub) => sub._id !== subcategoryId
          );
          setSubcategories(updatedSubcategories);
          setSnackba$everity("success");
          setSnackbarMessage("Subcategory deleted successfully!");
          setSnackbarOpen(true);
        } else {
          setSnackba$everity("warning");
          setSnackbarMessage(
            "Cannot delete subcategory with products. Please delete the products first !"
          );
          setSnackbarOpen(true);
        }
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const handleEditSubcategoryChange = (event) => {
    const { name, value } = event.target;
    setCurrentSubcategory({ ...currentSubcategory, [name]: value });
  };

  const submitEditSubcategory = () => {
    axios
      .put(
        `${process.env.REACT_APP_APIURL}api/sub/Category/`,
        {
          categoryId: categoriesid,
          subcategoryId: currentSubcategory._id,
          subcategoryname: currentSubcategory.subcategoryname,
        },
        {
          headers: { Authorization: "Agriuservalidation " + token },
        }
      )
      .then((response) => {
        const updatedSubcategories = subcategories.map((subcategory) => {
          if (subcategory._id === currentSubcategory._id) {
            return {
              ...subcategory,
              subcategoryname: currentSubcategory.subcategoryname,
            };
          }
          return subcategory;
        });

        setSubcategories(updatedSubcategories);
        setSnackba$everity("success");
        setSnackbarMessage("Subcategory updated successfully!");
        setSnackbarOpen(true);
        setEditModeSubcategory(false);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const handleNewSubcategoryChange = (event) => {
    setNewSubcategoryName(event.target.value);
  };

  const handleAddSubcategory = () => {
    axios
      .post(
        `${process.env.REACT_APP_APIURL}api/sub/Category`,
        {
          subcategoryname: newSubcategoryName,
          _id: categoriesid, // parent category ID
        },
        {
          headers: { Authorization: "Agriuservalidation " + token },
        }
      )
      .then((response) => {
        const newSubcategory =
          response.data.category.subcategories.slice(-1)[0]; // Assuming the new subcategory is the last item in the array
        setSubcategories([...subcategories, newSubcategory]);
        setSnackba$everity("success");
        setSnackbarMessage("New subcategory added successfully!");
        setSnackbarOpen(true);
        setAddSubcategoryDialogOpen(false);
        setNewSubcategoryName("");
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const handleApiError = (error) => {
    if (error.response) {
      const statusCode = error.response.status;
      if (
        statusCode === 400 &&
        error.response.data.error.includes("Cannot delete")
      ) {
        setSnackba$everity("warning");
        setSnackbarMessage(error.response.data.error);
        setSnackbarOpen(true);
      } else if (statusCode === 400) {
        setseverity("error");
        setMessage("Token expired, please login again.");
        setOpenSnackbarerror(true);
        setTimeout(() => {
          dispatch(logout());
          navigate("/login");
        }, 7000); // 7000ms to show the snackbar
      } else {
        setseverity("error");
        setMessage("An error occurred. Please try again later.");
        setOpenSnackbarerror(true);
      }
    } else if (error.request) {
      setseverity("error");
      setMessage(
        "No response from server. Please check your network connection."
      );
      setOpenSnackbarerror(true);
    } else {
      setseverity("error");
      setMessage(`Error: ${error.message}`);
      setOpenSnackbarerror(true);
    }
  };

  return (
    <Grid
      container
      spacing={2}
      p={isMobile || isTablet ? 2 : 5}
      sx={{ mt: isMobile || isTablet ? -2 : 0 }}
    >
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        severity={snackba$everity}
        message={snackbarMessage}
      />
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: isMobile || isTablet ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          label="Search Categories"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            mb: isMobile || isTablet ? 2 : 0,
            width: isMobile || isTablet ? "100%" : "auto",
          }}
          style={{ margin: "10px 0", marginLeft: "-15px" }}
        />

        <Button variant="contained" onClick={() => handleOpen()} sx={{ mb: 2 }}>
          Add New Category
        </Button>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category Name</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Subcategories</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isEmpty && (
              <TableRow>
                <TableCell colSpan={8}>
                  <Box
                    mt={7}
                    component={Typography}
                    sx={{ textAlign: "center" }}
                  >
                    No Categories found
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {!isEmpty &&
              isLoaded &&
              categories.map((category) => (
                <TableRow key={category._id}>
                  <TableCell>{category.categoryname}</TableCell>
                  <TableCell>
                    <img
                      src={category.categoryimages}
                      alt={category.categoryname}
                      style={{ width: "50px" }}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleSubcategoryDialogOpen(category._id)}
                    >
                      Manage Subcategories
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{ m: 1 }}
                      onClick={() => handleOpen(category, true, category._id)}
                    >
                      Edit
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => handleDeleteAction(category._id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}

            {!isLoaded && (
              <TableRow>
                <TableCell colSpan={8}>
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Box display="flex" justifyContent="center" p={2}>
          <Button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            Previous
          </Button>
          <Typography>{`Page ${currentPage} of ${totalPages}`}</Typography>
          <Button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next
          </Button>
        </Box>
      </TableContainer>
      <Dialog
        fullWidth={isMobile || isTablet}
        maxWidth="md"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
          {isEditMode ? "Edit Category" : "Add Category"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Category Name"
                type="text"
                fullWidth
                variant="standard"
                value={currentCategory.categoryname}
                onChange={(e) =>
                  setCurrentCategory({
                    ...currentCategory,
                    categoryname: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} width={"100%"}>
              <Box display="flex" justifyContent="center" width={"100%"}>
                <label
                  htmlFor="image-dp"
                  sx={{
                    marginBottom: "10px",
                  }}
                >
                  <Avatar
                    src={previewUrl && previewUrl}
                    variant="square"
                    mb={2}
                    sx={{
                      borderRadius: "4px",
                      color: "#333",
                      width: { ...{ xs: "70%", md: "100%" } },
                      maxWidth: 300,
                      maxHeight: 300,

                      height: "auto",
                      cu$or: "pointer",
                      pl: { xs: "15%", md: "0" },
                    }}
                  >
                    {!previewUrl && (
                      <CloudUploadIcon
                        sx={{
                          color: "#333",
                          width: 300,
                          height: 300,
                        }}
                      />
                    )}
                  </Avatar>
                </label>

                <input
                  hidden
                  id="image-dp"
                  type={"file"}
                  onChange={handleImageChange}
                  sx={{
                    marginTop: "10px",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={subcategoryDialogOpen}
        onClose={() => setSubcategoryDialogOpen(false)}
      >
        <DialogTitle>Manage Subcategories</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setSubcategoryDialogOpen(false)}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <List>
            {isEmptysub && subcategories.length === 0 && (
              <ListItem>
                {" "}
                <Typography>No subcategories found</Typography>{" "}
              </ListItem>
            )}

            {!isEmptysub &&
              isLoadedsub &&
              subcategories.map((subcategory, index) => (
                <ListItem key={index} style={{ paddingRight: 48 }}>
                  <ListItemText
                    primary={subcategory.subcategoryname}
                    primaryTypographyProps={{
                      noWrap: true, // This will prevent the text from wrapping and being cut off
                      style: { marginRight: 36 }, // Adjust the marginRight as necessary
                    }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => editSubcategory(subcategory)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deleteSubcategory(subcategory._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}

            <ListItem sx={{ display: "flex", justifyContent: "right" }}>
              {" "}
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => setAddSubcategoryDialogOpen(true)}
              >
                <AddCircleIcon />
              </IconButton>
            </ListItem>

            {!isLoadedsub && (
              <ListItem>
                <Skeleton animation="wave" height={60} />{" "}
                <Skeleton animation="wave" height={60} />
              </ListItem>
            )}
          </List>

          <Box display="flex" justifyContent="center" p={2}>
            <Button
              color="secondary"
              onClick={() =>
                handleSubcategoryDialogOpen(
                  categoriesid,
                  currentPagesubcategory - 1
                )
              }
              disabled={currentPagesubcategory === 1}
            >
              Previous
            </Button>
            <Typography>
              {`Page ${currentPagesubcategory} of ${totalPagessubcategory}`}
            </Typography>
            <Button
              color="secondary"
              onClick={() =>
                handleSubcategoryDialogOpen(
                  categoriesid,
                  currentPagesubcategory + 1
                )
              }
              disabled={currentPagesubcategory === totalPagessubcategory}
            >
              Next
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={editModeSubcategory}
        onClose={() => setEditModeSubcategory(false)}
      >
        <DialogTitle>Edit Subcategory</DialogTitle>
        <DialogContent>
          <TextField
            label="Subcategory Name"
            name="subcategoryname"
            value={currentSubcategory.subcategoryname || ""}
            onChange={handleEditSubcategoryChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditModeSubcategory(false)}>Cancel</Button>
          <Button onClick={submitEditSubcategory} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={addSubcategoryDialogOpen}
        onClose={() => setAddSubcategoryDialogOpen(false)}
      >
        <DialogTitle>Add New Subcategory</DialogTitle>
        <DialogContent>
          <TextField
            label="Subcategory Name"
            value={newSubcategoryName}
            onChange={handleNewSubcategoryChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddSubcategoryDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleAddSubcategory} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={isMobile || isTablet}
        maxWidth="md"
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default CategoryManagement;
