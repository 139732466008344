// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Typography,
//   CircularProgress,
// } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import Header from '../../Components/Header';
// import Footer from '../../Components/Footer';
// import { useSelector } from 'react-redux';
// import SimpleAgriCardforMobileview from '../Utils/SimpleAgriCardforMobileview';

// function MobileviewCategory() {
//   const { token } = useSelector((state) => state.loging);
//   const [categories, setCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [expandedSubcategory, setExpandedSubcategory] = useState(null);
//   const [loadingCategories, setLoadingCategories] = useState(false);
//   const [loadingProducts, setLoadingProducts] = useState(false);
//   const [products, setProducts] = useState([]);
//   const vendorID = useSelector((state) => state.vendorID.vendorID);
//   useEffect(() => {
//     setLoadingCategories(true);
//     axios
//       .get(`${process.env.REACT_APP_APIURL}api/vendorone/categorys/${vendorID}`)
//       .then((response) => {
//         console.log(response.data);
//         setCategories(response.data);
//         if (response.data.length > 0) {
//           setSelectedCategory(response.data[0]);
//         }
//       })
//       .catch((error) => {
//         console.error('Error fetching categories:', error);
//       })
//       .finally(() => {
//         setLoadingCategories(false);
//       });
//   }, []);

//   const handleCategoryClick = (category) => {
//     setSelectedCategory(category);
//     setExpandedSubcategory(null); // Reset subcategory expansion when new category is clicked
//     setProducts([]); // Clear products when switching categories
//   };

//   const handleSubcategoryClick = (subcategory) => {
//     const isExpanding = expandedSubcategory !== subcategory._id;
//     setExpandedSubcategory(isExpanding ? subcategory._id : null);
//     if (isExpanding) {
//       fetchProducts(subcategory);
//     }
//   };

//   const fetchProducts = (subcategory) => {
//     setLoadingProducts(true);
//     axios
//       .get(
//         `${process.env.REACT_APP_APIURL}api/subcategorytoFindProduct/${subcategory._id}?categoryId=${selectedCategory._id}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       )
//       .then((response) => {
//         setProducts(response.data.products);
//       })
//       .catch((error) => {
//         console.error('An error occurred while fetching products:', error);
//       })
//       .finally(() => {
//         setLoadingProducts(false);
//       });
//   };

//   return (
//     <>
//       <Header page='category' token={token} />
//       <div className='flex h-full bg-white'>
//         {/* Category List */}
//         <div className='w-1/4 overflow-y-auto sticky top-0'>
//           {loadingCategories ? (
//             <div className='flex justify-center items-center h-full'>
//               <CircularProgress />
//             </div>
//           ) : (
//             categories.map((category) => (
//               <div key={category._id} className='border-b border-gray-300'>
//                 <button
//                   className={`w-full p-3 text-left ${
//                     selectedCategory && selectedCategory._id === category._id
//                       ? 'bg-gray-300'
//                       : ''
//                   }`}
//                   onClick={() => handleCategoryClick(category)}
//                 >
//                   <img
//                     className='inline-block w-6 h-6 mr-2 object-contain'
//                     src={category.categoryimages}
//                     alt={category.categoryname}
//                   />
//                   <span className='text-sm font-medium'>
//                     {category.categoryname}
//                   </span>
//                 </button>
//               </div>
//             ))
//           )}
//         </div>

//         {/* Subcategory Accordion */}
//         <div className='w-3/4 overflow-x-auto scroll-auto sticky top-0 right-0 left-0'>
//           {selectedCategory ? (
//             <div className='sticky top-0 bg-white z-10'>
//               {selectedCategory.subcategories.length > 0 ? (
//                 selectedCategory.subcategories.map((subcategory) => (
//                   <Accordion
//                     key={subcategory._id}
//                     expanded={expandedSubcategory === subcategory._id}
//                     onChange={() => handleSubcategoryClick(subcategory)}
//                   >
//                     <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//                       <Typography>{subcategory.subcategoryname}</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails className='flex flex-wrap'>
//                       {loadingProducts ? (
//                         <div className='flex justify-center items-center w-full'>
//                           <CircularProgress />
//                         </div>
//                       ) : (
//                         <>
//                           {products.length > 0 ? (
//                             products.map((product) => (
//                               <SimpleAgriCardforMobileview
//                                 key={product._id}
//                                 product={product}
//                               />
//                             ))
//                           ) : (
//                             <Typography className='p-10 w-full text-center'>
//                               No products found.
//                             </Typography>
//                           )}
//                         </>
//                       )}
//                     </AccordionDetails>
//                   </Accordion>
//                 ))
//               ) : (
//                 <Typography className='w-full text-center pt-20'>
//                   No subcategories found.
//                 </Typography>
//               )}
//             </div>
//           ) : (
//             <Typography className='w-full text-center'>
//               Select a category to view subcategories.
//             </Typography>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// }

// export default MobileviewCategory;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { useSelector } from 'react-redux';
import SimpleAgriCardforMobileview from '../Utils/SimpleAgriCardforMobileview';

function MobileviewCategory() {
  const { token } = useSelector((state) => state.loging);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [expandedSubcategory, setExpandedSubcategory] = useState(null);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const vendorID = useSelector((state) => state.vendorID.vendorID);

  useEffect(() => {
    setLoadingCategories(true);
    axios
      .get(`${process.env.REACT_APP_APIURL}api/vendorone/categorys/${vendorID}`)
      .then((response) => {
        console.log(response.data);
        setCategories(response.data);
        if (response.data.length > 0) {
          setSelectedCategory(response.data[0]);
        }
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      })
      .finally(() => {
        setLoadingCategories(false);
      });
  }, [vendorID]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setExpandedSubcategory(null);
    setProducts([]);
  };

  const handleSubcategoryClick = (subcategory) => {
    const isExpanding = expandedSubcategory !== subcategory._id;
    setExpandedSubcategory(isExpanding ? subcategory._id : null);
    if (isExpanding) {
      fetchProducts(subcategory);
    }
  };

  const fetchProducts = (subcategory) => {
    setLoadingProducts(true);
    axios
      .get(
        `${process.env.REACT_APP_APIURL}api/subcategorytoFindProduct/${subcategory._id}?categoryId=${selectedCategory._id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setProducts(response.data.products);
      })
      .catch((error) => {
        console.error('An error occurred while fetching products:', error);
      })
      .finally(() => {
        setLoadingProducts(false);
      });
  };

  return (
    <>
      <Header page='category' token={token} />
      <div className='flex h-full bg-white'>
        {/* Category List */}
        <div className='w-1/4 overflow-y-hidden overflow-x-hidden sticky top-0 bg-gray-100'>
          {loadingCategories ? (
            <div className='flex justify-center items-center h-full'>
              <CircularProgress />
            </div>
          ) : (
            <List>
              {categories.map((category) => (
                <ListItem
                  button
                  key={category._id}
                  className={`${
                    selectedCategory && selectedCategory._id === category._id
                      ? 'bg-blue-500 text-white'
                      : ''
                  }`}
                  onClick={() => handleCategoryClick(category)}
                  disabled={category.subcategories.length === 0}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#FFFFFF' },
                  }}
                >
                  <ListItemText
                    sx={{ color: 'black' }}
                    primary={
                      <img
                        className='w-8 h-8 object-contain'
                        src={category.categoryimages}
                        alt={category.categoryname}
                      />
                    }
                    secondary={category.categoryname}
                  />

                  {/* <Typography> {category.categoryname} </Typography> */}
                </ListItem>
              ))}
            </List>
          )}
        </div>

        {/* Subcategory Accordion */}
        <div className='w-3/4 overflow-y-auto bg-white'>
          {selectedCategory ? (
            <div className='p-4'>
              {selectedCategory.subcategories.length > 0 ? (
                selectedCategory.subcategories.map((subcategory) => (
                  <Accordion
                    key={subcategory._id}
                    expanded={expandedSubcategory === subcategory._id}
                    onChange={() => handleSubcategoryClick(subcategory)}
                    className='my-2'
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className='text-lg font-semibold'>
                        {subcategory.subcategoryname}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className='flex flex-wrap'>
                      {loadingProducts ? (
                        <div className='flex justify-center items-center w-full'>
                          <CircularProgress />
                        </div>
                      ) : (
                        <>
                          {products.length > 0 ? (
                            products.map((product) => (
                              <SimpleAgriCardforMobileview
                                key={product._id}
                                product={product}
                              />
                            ))
                          ) : (
                            <Typography className='p-10 w-full text-center'>
                              No products found.
                            </Typography>
                          )}
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <Typography className='w-full text-center py-20'>
                  No subcategories found.
                </Typography>
              )}
            </div>
          ) : (
            <Typography className='w-full text-center py-20'>
              Select a category to view subcategories.
            </Typography>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MobileviewCategory;
