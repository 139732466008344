import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Container,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomSnackbar from '../Adminpages/Components/CustomSnackbar';
import CustomButton from '../../Components/CustomButton';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    let tempErrors = {};
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/; // Simple regex for email validation

    if (!email) {
      tempErrors.email = 'This field is required.';
    } else if (!emailRegex.test(email)) {
      tempErrors.email = 'Email is not valid.';
    } else {
      tempErrors.email = '';
    }

    setErrors({ ...tempErrors });

    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setSnackMessage('Please correct the errors before submitting.');
      setSnackSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_APIURL}users/request-password-reset`,
        {
          email,
        }
      );

      setSnackMessage('Please check your email for the password reset link.');
      setSnackSeverity('success');
      setOpenSnackbar(true);

      setEmail('');

      // Optionally, navigate the user to another page
    } catch (error) {
      setSnackMessage('Failed to send password reset email.');
      setSnackSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <CustomSnackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        severity={snackSeverity}
        message={snackMessage}
      />
      <Container component='main' maxWidth='xs'>
        <Paper
          elevation={3}
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: (3, 2),
          }}
        >
          <Typography component='h1' variant='h5'>
            Password Reset
          </Typography>
          <Box
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!errors.email}
              helperText={errors.email}
            />
            <CustomButton title='Send Reset Link' type='submit' />
            <Grid container>
              <Grid item>
                <Link href='/login' variant='body2'>
                  Remember your password? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
      <CustomSnackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        severity={snackSeverity}
        message={snackMessage}
      />
    </Box>
  );
}

export default ForgotPassword;
