import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Tooltip,
  Typography,
  IconButton,
} from '@mui/material';
import Header from '../../Components/Header';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from '../../Components/Alert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSelector, useDispatch } from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import { logout } from '../../Store/auth';
import CustomSnackbar from '../Adminpages/Components/CustomSnackbar';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import CloseIcon from '@mui/icons-material/Close';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function NewProduct(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [categories, setCategories] = useState([]);

  const [anchorElSub, setAnchorElSub] = useState(null);
  const openSub = Boolean(anchorElSub);

  const [subCategories, setSubCategories] = useState([]);
  const [errors, setErrors] = useState({});

  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [category, setCategory] = useState({
    categoryID: '',
    categoryName: '',
  });
  const [subCategory, setSubCategory] = useState({
    sub_categoryID: '',
    sub_categoryName: '',
  });

  // New State Variables
  const [ID, setID] = useState('');
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [stock, setStock] = useState('');
  const [rating, setRating] = useState(0);
  const [imagePreviews, setImagePreviews] = useState(new Array(5).fill(null));
  const [oldImages, setOldImages] = useState([]);
  const [images, setImages] = useState([]);
  const [error, setError] = useState('');

  // Hooks
  const { token, userID } = useSelector((state) => state.loging);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APIURL}api/Category/Forcustomer/`, {
        headers: { Authorization: 'Agriuservalidation ' + token },
      })
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((error) => {
        handleRequestError(error);
      });

    if (id) {
      axios
        .get(`${process.env.REACT_APP_APIURL}api/products/${id}`, {
          headers: { Authorization: 'Agriuservalidation ' + token },
        })
        .then((res) => {
          if (res.data.user_id !== userID) {
            setSnackbarMessage('You are not authorized to edit !!');
            setSnackbarSeverity('error');
            setOpenSnackbarSuccess(true);
            setTimeout(() => {
              navigate('/', { replace: true });
            }, 1000);
          }
          if (res.data) {
            setOldImages(res.data.images);
            const initialPreviews = new Array(5).fill(null);
            res.data.images.forEach((image, index) => {
              initialPreviews[index] = image;
            });
            setImagePreviews(initialPreviews);
            setTitle(res.data.title);
            setPrice(res.data.price);
            setDescription(res.data.description);
            setCategory({
              categoryID: res.data.category.categoryID,
              categoryName: res.data.category.categoryName,
            });
            setStock(res.data.stock);
            setRating(res.data.rating);
            setSubCategory({
              sub_categoryID: res.data.sub_category.sub_categoryID,
              sub_categoryName: res.data.sub_category.sub_categoryName,
            });
            setID(res.data.id);
            setImages(res.data.images);
          }
        })
        .catch((error) => {
          handleRequestError(error);
        });
    }
  }, []);

  const handleRequestError = (error) => {
    if (error.response) {
      const statusCode = error.response.status;
      if (statusCode === 400) {
        setSeverity('error');
        setMessage('Token expired, please login again.');
        setOpenSnackbar(true);
        setTimeout(() => {
          dispatch(logout());
          navigate('/login');
        }, 7000);
      } else {
        setSeverity('error');
        setMessage('An error occurred. Please try again later.');
        setOpenSnackbar(true);
      }
    } else if (error.request) {
      setSeverity('error');
      setMessage(
        'No response from server. Please check your network connection.'
      );
      setOpenSnackbar(true);
    } else {
      setSeverity('error');
      setMessage(`Error: ${error.message}`);
      setOpenSnackbar(true);
    }
  };

  // const handleFileChange = (event, index) => {
  //   const file = event.target.files[0];
  //   const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
  //   if (file && validImageTypes.includes(file.type)) {
  //     const newImages = [...images];
  //     const newImagePreviews = [...imagePreviews];
  //     newImages[index] = file;
  //     newImagePreviews[index] = URL.createObjectURL(file);
  //     setImages(newImages);
  //     setImagePreviews(newImagePreviews);
  //   } else {
  //     setError('Invalid file type. Only JPG, PNG, and GIF files are allowed.');
  //   }
  // };

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (file && validImageTypes.includes(file.type)) {
      if (file.size <= 1048576) {
        // 1MB = 1048576 bytes
        const newImages = [...images];
        const newImagePreviews = [...imagePreviews];
        newImages[index] = file;
        newImagePreviews[index] = URL.createObjectURL(file);
        setImages(newImages);
        setImagePreviews(newImagePreviews);
      } else {
        setError('File size should be less than 1MB.');
      }
    } else {
      setError('Invalid file type. Only JPG, PNG, and GIF files are allowed.');
    }
  };

  const handleRemoveImage = (index) => {
    const newImages = [...images];
    const newImagePreviews = [...imagePreviews];
    newImages[index] = null;
    newImagePreviews[index] = null;
    setImages(newImages);
    setImagePreviews(newImagePreviews);

    if (index < oldImages.length) {
      const newOldImages = [...oldImages];
      newOldImages.splice(index, 1);
      setOldImages(newOldImages);
    }
  };

  const validateForm = () => {
    let tempErrors = {};
    tempErrors.title = title ? '' : 'Product name is required.';
    tempErrors.price = price ? '' : 'Price is required.';
    if (price && isNaN(price)) tempErrors.price = 'Price must be a number.';
    tempErrors.description = description ? '' : 'Description is required.';
    tempErrors.stock = stock ? '' : 'Stock is required.';
    if (stock && isNaN(stock)) tempErrors.stock = 'Stock must be a number.';
    tempErrors.category = category.categoryName ? '' : 'Category is required.';
    tempErrors.subCategory = subCategory.sub_categoryName
      ? ''
      : 'Sub Category is required.';
    tempErrors.rating = rating > 0 ? '' : 'Rating is required.';

    if (!oldImages.length && !images.filter(Boolean).length) {
      tempErrors.images = 'At least one product image is required.';
    }
    setErrors({ ...tempErrors });

    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateForm()) {
      setError('Please correct the errors before submitting.');
      return;
    }

    const data = new FormData();

    data.append('title', title);
    data.append('price', price);
    data.append('description', description);
    data.append('stock', stock);
    data.append('category', JSON.stringify(category));
    data.append('sub_category', JSON.stringify(subCategory));
    data.append('_id', id ? id : '');
    data.append('id', ID);
    data.append('user_id', userID);
    data.append('rating', rating);

    // Append each image to the FormData object
    images.filter(Boolean).forEach((image) => {
      data.append('images', image);
    });

    // Append old image links to the FormData object
    data.append('oldImages', JSON.stringify(oldImages));

    const request = id
      ? axios.put(`${process.env.REACT_APP_APIURL}api/products`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Agriuservalidation ' + token,
          },
        })
      : axios.post(`${process.env.REACT_APP_APIURL}api/products`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Agriuservalidation ' + token,
          },
        });

    request
      .then((res) => {
        setSnackbarMessage(
          id
            ? 'Product Updated Successfully !!'
            : 'Product Added Successfully !!'
        );
        setSnackbarSeverity('success');
        setOpenSnackbarSuccess(true);
        setTimeout(() => {
          navigate('/');
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        handleRequestError(error);
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosedropdown = () => {
    setAnchorEl(null);
  };

  const handleClickSub = (event) => {
    setAnchorElSub(event.currentTarget);
  };

  const handleClosedropdownSub = () => {
    setAnchorElSub(null);
  };

  const handleClickcategroy = (subCat) => {
    setSubCategories(subCat);
    setSubCategory({ sub_categoryID: '', sub_categoryName: '' }); // Reset subcategory selection
  };

  return (
    <>
      <Header token={token} />
      <Alert
        open={!!error}
        handleClose={() => setError('')}
        title='Alert!'
        msg={error}
      />
      <CustomSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        severity={severity}
        message={message}
      />
      <CustomSnackbar
        open={openSnackbarSuccess}
        onClose={() => setOpenSnackbarSuccess(false)}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />

      <Box
        component={Paper}
        elevation={0}
        square
        minHeight={'82vh'}
        py={3}
        sx={{ backgroundColor: '#fff' }}
      >
        <Container maxWidth='md'>
          <Typography
            variant='h3'
            sx={{ mb: 3, color: 'primary.main', fontFamily: 'open sans' }}
          >
            {id ? 'Update Product' : 'New Product'}
          </Typography>
          <Grid
            component={Paper}
            elevation={2}
            container
            justifyContent={'center'}
            alignItems='center'
            sx={{ border: '2px solid #232F3E', p: 2, borderRadius: 2 }}
          >
            <Grid item xs={12} md={5}>
              <Box p={{ md: 0, xs: 3 }}>
                <Grid
                  container
                  columnSpacing={0.5}
                  rowSpacing={0.5}
                  sx={{ mb: 2 }}
                  justifyContent='space-around'
                >
                  {imagePreviews.map((preview, index) => (
                    <Grid item key={index} position='relative'>
                      <Tooltip title='Select image'>
                        <label htmlFor={`file_${index}`}>
                          <Box
                            p={0.5}
                            sx={{
                              bgcolor: '#D8D8D8',
                              borderRadius: 1,
                              cursor: 'pointer',
                              position: 'relative',
                            }}
                          >
                            {preview ? (
                              <img
                                style={{ width: 70, height: 70, margin: 1 }}
                                src={preview}
                                alt='Product Preview'
                              />
                            ) : (
                              <AddToPhotosIcon
                                sx={{
                                  color: '#406882',
                                  width: 72,
                                  height: 72,
                                }}
                              />
                            )}
                          </Box>
                        </label>
                      </Tooltip>
                      {preview && (
                        <IconButton
                          size='small'
                          sx={{ position: 'absolute', top: -5, right: -5 }}
                          onClick={() =>
                            handleRemoveImage(index, index < oldImages.length)
                          }
                        >
                          <CloseIcon
                            fontSize='small'
                            sx={{ color: '#406882' }}
                          />
                        </IconButton>
                      )}
                      <input
                        type='file'
                        hidden
                        id={`file_${index}`}
                        accept='image/*'
                        onChange={(event) => handleFileChange(event, index)}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Typography>Only you can add 5 images</Typography>
                {errors.images && (
                  <Typography color='error' variant='caption'>
                    {errors.images}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={7}>
              <Box
                component='form'
                noValidate
                onSubmit={handleSubmit}
                sx={{ p: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>Product Name</Typography>
                    <TextField
                      autoComplete='given-name'
                      name='Product name'
                      required
                      fullWidth
                      inputProps={{ sx: { color: 'primary.main' } }}
                      value={title}
                      onChange={(event) => setTitle(event.target.value)}
                      id='name'
                      placeholder='Product Name'
                      error={!!errors.title}
                      helperText={errors.title}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Price</Typography>
                    <TextField
                      fullWidth
                      inputProps={{ sx: { color: 'primary.main' } }}
                      value={price}
                      onChange={(event) => setPrice(event.target.value)}
                      id='price'
                      placeholder='Price'
                      name='price'
                      autoComplete='price'
                      type='number'
                      error={!!errors.price}
                      helperText={errors.price}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Category</Typography>
                    <Button
                      id='category-button'
                      aria-controls={open ? 'demo-customized-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      variant='outlined'
                      disableElevation
                      fullWidth
                      onClick={handleClick}
                      endIcon={<KeyboardArrowDownIcon />}
                      sx={{ p: 1.5 }}
                    >
                      {category.categoryName || 'Category'}
                    </Button>
                    <StyledMenu
                      id='demo-customized-menu'
                      MenuListProps={{ 'aria-labelledby': 'category-button' }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClosedropdown}
                    >
                      {categories.map((cat) => (
                        <MenuItem
                          key={cat.categoryname}
                          onClick={() => {
                            setCategory({
                              categoryID: cat._id,
                              categoryName: cat.categoryname,
                            });
                            handleClickcategroy(cat.subcategories);
                            handleClosedropdown();
                          }}
                        >
                          {cat.categoryname}
                        </MenuItem>
                      ))}
                    </StyledMenu>
                    {errors.category && (
                      <Typography color='error' variant='caption'>
                        {errors.category}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Sub Category</Typography>
                    <Button
                      id='subCategory-button'
                      aria-controls={openSub ? 'subCategory-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={openSub ? 'true' : undefined}
                      variant='outlined'
                      disableElevation
                      fullWidth
                      onClick={handleClickSub}
                      disabled={!subCategories.length}
                      endIcon={<KeyboardArrowDownIcon />}
                      sx={{ p: 1.5 }}
                    >
                      {subCategory.sub_categoryName || 'Sub Category'}
                    </Button>
                    <StyledMenu
                      id='subCategory-menu'
                      MenuListProps={{
                        'aria-labelledby': 'subCategory-button',
                      }}
                      anchorEl={anchorElSub}
                      open={openSub}
                      onClose={handleClosedropdownSub}
                    >
                      {subCategories.map((subCat) => (
                        <MenuItem
                          key={subCat._id}
                          onClick={() => {
                            setSubCategory({
                              sub_categoryID: subCat._id,
                              sub_categoryName: subCat.subcategoryname,
                            });
                            handleClosedropdownSub();
                          }}
                        >
                          {subCat.subcategoryname}
                        </MenuItem>
                      ))}
                    </StyledMenu>
                    {errors.subCategory && (
                      <Typography color='error' variant='caption'>
                        {errors.subCategory}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Stock</Typography>
                    <TextField
                      required
                      fullWidth
                      inputProps={{ sx: { color: 'primary' } }}
                      value={stock}
                      onChange={(event) => setStock(event.target.value)}
                      id='stock'
                      placeholder='Stock'
                      name='Stock'
                      autoComplete='Stock'
                      type='number'
                      error={!!errors.stock}
                      helperText={errors.stock}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component='legend'>Rating</Typography>
                    <Rating
                      name='rating'
                      value={rating}
                      onChange={(event, newValue) => setRating(newValue)}
                      error={!!errors.rating}
                      helperText={errors.rating}
                    />
                    {errors.rating && (
                      <Typography color='error' variant='caption'>
                        {errors.rating}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Description</Typography>
                    <TextField
                      height={1000}
                      maxRows={4}
                      required
                      fullWidth
                      inputProps={{ sx: { color: 'primary.main' } }}
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                      id='description'
                      placeholder='Description'
                      name='description'
                      autoComplete='description'
                      multiline
                      error={!!errors.description}
                      helperText={errors.description}
                    />
                  </Grid>
                </Grid>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{
                    backgroundColor: 'secondary.main',
                    color: '#fff',
                    mt: 3,
                    '&:hover': { bgcolor: 'secondary.main', color: '#000' },
                  }}
                >
                  {id ? 'Update' : 'Add'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default NewProduct;
