import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Typography, useMediaQuery, useTheme } from '@mui/material';

function CustomerOrdersAccordion({ product }) {
  console.log('helowlwl' + product);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <ListItem
        style={{
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: '100%',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <ListItemAvatar>
          <Avatar
            src={product.images[0]}
            alt={product.title}
            variant='square'
            style={{
              width: isMobile ? 48 : 60,
              height: isMobile ? 48 : 60,
              marginRight: isMobile ? 0 : 16,
              marginBottom: isMobile ? 8 : 0,
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                variant={isMobile ? 'subtitle1' : 'body1'}
                component='span'
                style={{ fontWeight: 'bold' }}
              >
                {product.title}
              </Typography>
              <Typography
                variant='body2'
                component='span'
                style={{ color: 'gray', display: 'block' }}
              >
                Price: Rs {product.price}
              </Typography>
              <Typography
                variant='body2'
                component='span'
                style={{ color: 'gray', display: 'block' }}
              >
                Category: {product?.category?.categoryName}
              </Typography>
              <Typography
                variant='body2'
                component='span'
                style={{ color: 'gray', display: 'block' }}
              >
                sub Category: {product?.sub_category?.sub_categoryName}
              </Typography>
            </React.Fragment>
          }
          secondary={`Quantity: ${product.quantity}`}
          secondaryTypographyProps={{ style: { color: 'gray' } }}
        />
      </ListItem>
    </>
  );
}

export default CustomerOrdersAccordion;
