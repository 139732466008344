import React from 'react';
import {
  Box,
  Container,
  Grid,
  Pagination,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Header from '../../Components/Header';
import AgriCard from '../Utils/AgriCard';
import axios from 'axios';
import { useEffect, useState } from 'react';
import AgriSkelton from '../../Pages/AllSkeleton/AgriSkelton';
import Ack from '../../Components/Ack';
import AdminNav from '../AdminNavigation/AdminNav';
import AdminDashboard from '../Adminpages/AdminDashboard';
import VendorManagement from '../Adminpages/VendorManagement';
import CustomerManagement from '../Adminpages/CustomerManagement';
import ProductManagement from '../Adminpages/ProductManagement';
import OrderManagement from '../Adminpages/OrderManagement';

import ImageSlider from '../Category/ImageSlider';

import CategoryManagement from '../Adminpages/CategoryManagement';
import CommunicationManagement from '../Adminpages/CommunicationManagement';
import Settings from '@mui/icons-material/Settings';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../Store/auth';
import CustomSnackbar from '../Adminpages/Components/CustomSnackbar';
import { useRef } from 'react';

import SearchResultList from '../SearchResultList/SearchResultList';
import VendorsideNavigationBar from '../VendorSideNavigationBar/VendorSideNavigationBar';
import Products from '../Profile/Components/Products';
import VendorOrders from '../Profile/Components/VendorOrders';
import Profile from '../Profile/Profile';
import VendorDashboard from '../VendorSideNavigationBar/VendorDashboradPages/VendorDashborad';
import { useLocation } from 'react-router-dom';
import CategorySidebar from '../Category/CategorySidebar';
import { fetchFavorites } from '../../Store/favoritesSlice';
import { fetchCartItems } from '../../Store/cartSlice';
import MobileImageSilder from '../Category/MobileImageSilder';
import EmptyUI from '../EmptyUI/EmptyUI';

function Dashboard(props) {
  const targetSectionRef = useRef(null);
  const [selectionCompleted, setSelectionCompleted] = useState(false);
  const formatAddress = (address) => {
    return `${address.address}, ${address.city}, ${address.province}, ${address.country},  ${address.postalcode}`;
  };
  // This function will be passed to VendorsDropdown and called upon selection completion
  const handleSelectionComplete = (isComplete) => {
    setSelectionCompleted(isComplete);
  };

  // Function to scroll to the target section
  const scrollToSection = () => {
    if (targetSectionRef.current) {
      targetSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //product data
  const [products, setProducts] = useState([]);

  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [favorites, setFavorites] = useState([]);
  const [carts, setcarts] = useState([]);
  const [isLoaded, setLoaded] = useState(false);

  const [isEmpty, setEmpty] = useState(false);

  const [selectedVendor, setSelectedVendor] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [severity, setseverity] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [currentvendor, setCurrentvendor] = useState(null);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedPageforvendor, setSelectedPageforvendor] =
    useState('dashboard');

  const [selectedPageforAdmin, setSelectedPageforAdmin] = useState('dashboard');

  const handleCloseAlert = () => {
    setOpenSnackbar(false);
  };

  const hadlecurrentvendor = (vendor) => {
    setCurrentvendor(vendor);
  };
  const [showAd, setShowAd] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAd(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    dispatch(fetchCartItems());
    dispatch(fetchFavorites());
  }, [dispatch]);

  // Function to close the ad modal
  const handleCloseAd = () => {
    setShowAd(false);
  };

  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = useState(false);

  const { token, userID, role } = useSelector((state) => state.loging);

  const vendorID = useSelector((state) => state.vendorID.vendorID);
  console.log(vendorID);
  console.log('vendor id sjdskds dshdsjh' + vendorID);

  useEffect(() => {
    setSelectedVendor(vendorID);

    setLoading(true);
    axios

      .get(`${process.env.REACT_APP_APIURL}api/Category/Forcustomer/`)
      .then((response) => {
        setCategories(response.data.data);
        setLoading(false);
      })
      .catch((error) => {});
  }, [vendorID]);

  //popup
  const [open, setOpen] = useState(false);

  //product delete
  const [id, setID] = useState();
  const [index, setIndex] = useState();
  const [SeachResult, setSeachResult] = useState([]);

  //search product
  const searchHandler = (searchText) => {
    // setShowSearch(searchText !== '' ? true : false);
    if (!searchText) {
      setShowSearch(false);
      return;
    }
    setShowSearch(true);
    let urlsearch;
    if (role === 'Vendor') {
      urlsearch = `${process.env.REACT_APP_APIURL}api/products?pagination=${page}&title=${searchText}&owner=${userID}`;
    } else if (selectedVendor) {
      urlsearch = `${process.env.REACT_APP_APIURL}api/products?pagination=${page}&title=${searchText}&owner=${selectedVendor}`;
    }

    setPage(1);
    setLoaded(false);
    setEmpty(false);
    axios
      .get(`${urlsearch}`, {
        headers: { Authorization: 'Agriuservalidation ' + token },
      })
      .then((res) => {
        if (res.data.data.length > 0) {
          setProducts(res.data.data);
          setCount(Math.ceil(res.data.cdata / 12));
          setSeachResult(res.data.data);
          setLoaded(true);
          setEmpty(false);
          setShowSearch(true);
        } else {
          setShowSearch(true);
          setSeachResult(res.data.data.length === 0 ? 0 : res.data.data.length);
          setEmpty(true);
          setLoaded(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;

          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              console.log('Authentication failed. Logging out.');
              dispatch(logout());
              navigate('/login');
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other erro$
            // setseverity('error');
            // setMessage('An error occurred. Please try again later.');
            // setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setseverity('error');
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  };

  //find favorite products
  const findfav = (array, id) => {
    let val = false;
    array.forEach((data) => {
      if (data === id) {
        val = true;
      }
    });
    return val;
  };

  const findAddtoCart = (array, id) => {
    let val = false;
    array.forEach((data) => {
      if (data === id) {
        val = true;
      }
    });
    return val;
  };

  //page change[pagination]
  const handleChange = (event, value) => {
    setPage(value);
  };

  //useEffect call
  useEffect(() => {
    let urlget;

    if (role === 'Vendor') {
      urlget = `${process.env.REACT_APP_APIURL}api/products?pagination=${page}&owner=${userID}`;
    } else if (vendorID) {
      urlget = `${process.env.REACT_APP_APIURL}api/products?pagination=${page}&owner=${vendorID}`;
    }

    axios
      .get(`${urlget}`, {
        headers: { Authorization: 'Agriuservalidation ' + token },
      })

      .then((res) => {
        if (res.data.data.length > 0) {
          setProducts(res.data.data);
          setLoaded(true);
          setEmpty(false);

          const p_count = Math.ceil(+res.data.cdata / 12);
          setCount(p_count);
        } else {
          setEmpty(true);
          setLoaded(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);
            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              console.log('Authentication failed. Logging out.');
              dispatch(logout());
              navigate('/login');
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other erro$
            // setseverity('error');
            // setMessage('An error occurred. Please try again later.');
            // setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setseverity('error');
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });

    axios
      .get(`${process.env.REACT_APP_APIURL}users/favorites?_id=${userID}`, {
        headers: { Authorization: 'Agriuservalidation ' + token },
      })
      .then((res) => {
        if (res.data) {
          setFavorites(res.data);

          // dispatch(setFavorites(res.data));
        }
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              console.log('Authentication failed. Logging out.');
              dispatch(logout());
              navigate('/login');
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other erro$
            setseverity('error');
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setseverity('error');
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });

    axios
      .get(`${process.env.REACT_APP_APIURL}users/carts?_id=${userID}`, {
        headers: { Authorization: 'Agriuservalidation ' + token },
      })
      .then((res) => {
        if (res.data) {
          setcarts(res.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              console.log('Authentication failed. Logging out.');
              dispatch(logout());
              navigate('/login');
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other erro$
            setseverity('error');
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setseverity('error');
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  }, [page, selectedVendor]);

  //popup handler
  const handleClose = () => {
    setOpen(false);
  };

  //delete product
  const handleYes = () => {
    setOpen(false);

    axios
      .delete(`${process.env.REACT_APP_APIURL}api/products?_id=${id}`, {
        headers: { Authorization: 'Agriuservalidation ' + token },
      })
      .then((res) => {
        setProducts((pre) => {
          const array = [...pre];
          array.splice(index, 1);
          return array;
        });
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              console.log('Authentication failed. Logging out.');
              dispatch(logout());
              navigate('/login');
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other erro$
            setseverity('error');
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setseverity('error');
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  };

  //handle click delete btn
  const clickDelete = (id, index) => {
    setOpen(true);
    setID(id);
    setIndex(index);
  };
  const [currentPage, setCurrentPage] = useState('dashboard');

  const [currentforadmin, setCurrentforadmin] = useState('dashboard');

  const handleSelectPage = (page) => {
    setCurrentPage(page);
  };
  const handlecolor = (page) => {
    setCurrentPage(page);
  };

  const handlePageChangevendor = (page) => {
    setSelectedPageforvendor(page);
  };
  const hadlepagechangeadmin = (page) => {
    setCurrentforadmin(page);
    setSelectedPageforAdmin(page);
  };
  useEffect(() => {
    let urlget;

    if (selectedVendor) {
      urlget = `${process.env.REACT_APP_APIURL}api/withouttoken/Products?pagination=${page}&owner=${selectedVendor}`;
    }

    axios
      .get(`${urlget}`)
      // .get(`${urlget}`)
      .then((res) => {
        if (res.data.data.length > 0) {
          setProducts(res.data.data);
          setLoaded(true);
          setEmpty(false);

          const p_count = Math.ceil(+res.data.cdata / 12);
          setCount(p_count);
        } else {
          setEmpty(true);
          setLoaded(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [page, selectedVendor, page, count]);

  // #################################################################################################################

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [hoveredSubcategory, setHoveredSubcategory] = useState(null);
  const [Productscategory, setProductscategory] = useState([]);
  const [emptycategory, setEmptycategory] = useState(false);
  const [categoriess, setCategoriess] = useState([]);
  const [totalPagescategory, setTotalPagescategory] = useState(0);
  const [currentPagecategory, setCurrentPagecategory] = useState(1);
  const [categoryselected, setcategoryselected] = useState(false);
  const [loadingcategory, setLoadingcategoty] = useState(true);
  const [getAllproducts, setGetAllproducts] = useState([]);

  const fetchAllProducts = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIURL}api//productsforcustomer/getforcustomer`
      )
      .then((response) => {
        console.log('Fetched products:', response.data);
        setGetAllproducts(response.data);
      })
      .catch((error) => {
        console.error('Error fetching :', error);
      });
  };

  useEffect(() => {
    fetchAllProducts();
    if (selectedVendor) {
      axios
        .get(
          `${process.env.REACT_APP_APIURL}api/vendorone/categorys/${selectedVendor}`
        )
        .then((response) => {
          console.log('Fetched categories:', response.data);
          setCategoriess(response.data);
          setLoadingcategoty(false);
        })
        .catch((error) => {
          console.error('Error fetching categories:', error);
          setLoadingcategoty(false);
        });
    }
  }, [selectedVendor]);

  useEffect(() => {
    if (!hoveredSubcategory || !selectedCategory) return;
    fetchProducts(hoveredSubcategory);
  }, [hoveredSubcategory, currentPagecategory]);

  // const fetchProducts = (subcategory) => {
  //   if (!subcategory || !selectedCategory) return;
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_APIURL}api/subcategorytoFindProduct/${hoveredSubcategory._id}?categoryId=${selectedCategory._id}&page=${currentPage}&limit=12`
  //     )
  //     .then((response) => {
  //       setProductscategory(response.data.products);
  //       setProducts(response.data.products);
  //       setPage(response.data.totalPages);
  //       setEmpty(response.data.products.length === 0);
  //       setTotalPagescategory(response.data.totalPages);
  //       setEmptycategory(response.data.products.length === 0);
  //     })
  //     .catch((error) => {
  //       setcategoryselected(false);
  //       setLoadingcategoty(false);
  //       console.error('An error occurred while fetching products:', error);
  //     });
  // };

  const fetchProducts = (subcategory) => {
    if (!subcategory || !selectedCategory) return;

    // Assuming getAllProducts is the state where all products are stored
    const filteredProducts = getAllproducts.filter((product) => {
      return (
        product.sub_category.sub_categoryID === hoveredSubcategory._id &&
        product.category.categoryID === selectedCategory._id
      );
    });
    console.log('filteredProducts', filteredProducts);

    setProductscategory(filteredProducts);
    setProducts(filteredProducts);
    setPage(Math.ceil(filteredProducts.length / 12));
    setEmpty(filteredProducts.length === 0);
    setTotalPagescategory(Math.ceil(filteredProducts.length / 12));
    setEmptycategory(filteredProducts.length === 0);
  };

  const handleCategoryHover = (category) => {
    setSelectedCategory(category);
    setCurrentPagecategory(1); // Reset to the fi$t page when a new category is selected
  };

  const handleSubcategoryHover = (subcategory) => {
    setHoveredSubcategory(subcategory);
    setCurrentPagecategory(1); // Reset to the fi$t page when a new subcategory is hovered
    fetchProducts(subcategory);

    // onSubcategorySelect(subcategory);
  };

  const handlePageChange = (event, value) => {
    setCurrentPagecategory(value);
  };
  return (
    <>
      {role === 'client' && (
        <Header
          handler={handleSelectPage}
          token={token}
          searchHandler={searchHandler}
          vendorDetails={currentvendor}
        />
      )}
      <Ack
        title={'Alert'}
        open={open}
        handleClose={handleClose}
        msg='Are you sure to delete'
        handleYes={handleYes}
      />

      <CustomSnackbar
        open={openSnackbar}
        onClose={handleCloseAlert}
        severity={severity}
        message={message}
      />
      {/* Vendor  ################################################### */}

      {role === 'Vendor' && (
        <Grid container maxWidth={isMobile ? 'xs' : 'xll'} spacing={2}>
          <Grid item xs={12} sm={2} md={2} maxWidth={isMobile ? 'xs' : 'xl'}>
            <VendorsideNavigationBar
              open={sidebarOpen}
              selectedPage={selectedPageforvendor}
              handlePageChange={handlePageChangevendor}
              token1={token}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={10}
            md={10}
            lg={11}
            maxWidth={isMobile ? 'xs' : '100%'}
            sx={{ p: 2, mt: 5, ml: isMobile ? 0 : 35 }}
          >
            <Container maxWidth={isMobile ? 'xs' : 'xl'}>
              {selectedPageforvendor === 'dashboard' && <VendorDashboard />}
              {selectedPageforvendor === 'orders' && <VendorOrders />}
              {selectedPageforvendor === 'profile' && <Profile />}
              {selectedPageforvendor === 'products' && <Products />}
            </Container>
          </Grid>
        </Grid>
      )}

      {/* admindashboard   ################################################### */}
      {role === 'admin' && (
        <Grid
          container
          maxWidth={isMobile ? 'xs' : 'xll'}
          // maxHeight={isMobile ? "300px" : "1000px"}
          spacing={2}
          // backgroundColor={"#f5f5f5"}
        >
          <Grid item xs={12} sm={2} md={2} maxWidth={isMobile ? 'xs' : 'xll'}>
            <AdminNav
              selectedPage={selectedPageforAdmin}
              handlePageChange={hadlepagechangeadmin}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={10}
            md={10}
            lg={11}
            maxWidth={isMobile ? 'xs' : '100%'}
            sx={{ p: 1, mt: 1, ml: isMobile ? 1 : 35 }}
          >
            <Container maxWidth={isMobile ? 'xs' : 'xl'}>
              {currentforadmin === 'dashboard' && <AdminDashboard />}
              {currentforadmin === 'vendorManagement' && <VendorManagement />}
              {currentforadmin === 'customerManagement' && (
                <CustomerManagement />
              )}
              {currentforadmin === 'categoryManagement' && (
                <CategoryManagement />
              )}
              {currentforadmin === 'productManagement' && <ProductManagement />}
              {currentforadmin === 'orderManagement' && <OrderManagement />}
              {currentforadmin === 'communication' && (
                <CommunicationManagement />
              )}
              {currentforadmin === 'settings' && <Settings />}
            </Container>
          </Grid>
        </Grid>
      )}
      {/* VendorsDropdown   ################################################### */}

      {role === 'client' && (
        <Box>
          {role !== 'Vendor' && role !== 'admin' && (
            <Box
              component={Paper}
              elevation={1}
              square
              minHeight={'83vh'}
              // sx={{ backgroundColor: "#fff" }}
              sx={{
                height: '100%',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              {/* showSearch options   ################################################### */}
              <div
                sx={{
                  display: showSearch ? 'block' : 'none',
                }}
              >
                {showSearch && (
                  <SearchResultList
                    searchResult={SeachResult}
                    scrollToSection={scrollToSection}
                  />
                )}
              </div>

              <Grid
                container
                spacing={2}
                style={{
                  display: 'flex',
                  // flexDirection: "row",
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  textAlign: 'center',
                }}
              >
                <Grid
                  item
                  sx={{
                    display: isMobile ? 'none' : 'block',
                    zIndex: 1000,
                    position: 'absolute',
                    top: '60px',
                    left: '10px',
                    // right: "20px",

                    // bgcolor: "#fff",
                  }}
                >
                  <CategorySidebar
                    categoriess={categoriess}
                    hoveredSubcategory={hoveredSubcategory}
                    selectedCategory={selectedCategory}
                    emptycategory={emptycategory}
                    Productscategory={Productscategory}
                    currentPagecategory={currentPagecategory}
                    totalPagescategory={totalPagescategory}
                    handlePageChange={handlePageChange}
                    handleSubcategoryHover={handleSubcategoryHover}
                    handleCategoryHover={handleCategoryHover}
                  />
                </Grid>
                {isMobile ? (
                  <Grid
                    item
                    xs={12} // Takes full width on extra-small screens
                    sm={8} // Takes 2/3 of the width on small screens and larger
                    md={9} // Takes 3/4 of the width on medium screens and larger
                    lg={10} // Takes 5/6 of the width on large screens and larger
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        marginTop: '35px',

                        width: isMobile ? '100%' : '97%',
                        minHeight: '450px',
                      }}
                    >
                      {<MobileImageSilder />}
                    </Box>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12} // Takes full width on extra-small screens
                    sm={8} // Takes 2/3 of the width on small screens and larger
                    md={9} // Takes 3/4 of the width on medium screens and larger
                    lg={10} // Takes 5/6 of the width on large screens and larger
                  >
                    <Box
                      sx={{
                        // marginRight: "280px",
                        position: 'relative',
                        marginTop: '35px',

                        marginLeft: isMobile ? '10px' : '140px',

                        width: isMobile ? '100%' : '97%',
                        height: '400px',
                      }}
                    >
                      {<ImageSlider />}
                    </Box>
                  </Grid>
                )}
              </Grid>

              <br />

              {/* CategoryDialog  ###################################################*/}

              {/* Products   ################################################### */}

              <Container maxWidth='xll'>
                <Grid
                  ref={targetSectionRef}
                  container
                  direction='row'
                  justifyContent='center'
                  alignItems={'space-between'}
                  spacing={2}
                  minHeight='50vh'
                >
                  {isEmpty && (
                    <>
                      <Box
                        mt={7}
                        component={Typography}
                        sx={{ textAlign: 'center' }}
                      >
                        {/* No products Found */}

                        <EmptyUI />
                      </Box>
                    </>
                  )}
                  {!isEmpty &&
                    isLoaded &&
                    products.map((row, index) => {
                      // console.log(favorites);
                      const val = findfav(favorites, row._id);
                      const inCart = findAddtoCart(carts, row._id);
                      return (
                        <AgriCard
                          clickDelete={clickDelete}
                          key={row._id}
                          fav={val}
                          data={row}
                          index={index}
                          inCart={inCart}
                        />
                      );
                    })}
                  {!isLoaded && (
                    <>
                      <AgriSkelton />
                      <AgriSkelton />
                      <AgriSkelton />
                      <AgriSkelton />
                      <AgriSkelton />
                      <AgriSkelton />
                      <AgriSkelton />
                      <AgriSkelton />
                      <AgriSkelton />
                      <AgriSkelton />
                      <AgriSkelton />
                      <AgriSkelton />
                    </>
                  )}
                </Grid>
                <Box mt={3} />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flexGrow: 1 }} />
                  <Pagination
                    page={page}
                    count={count}
                    onChange={handleChange}
                    sx={{ color: '#232F3E ' }}
                  />
                  <Box sx={{ flexGrow: 1 }} />
                </Box>
              </Container>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

export default Dashboard;
