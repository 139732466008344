import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  IconButton,
  Skeleton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  useTheme,
  useMediaQuery,
  Chip,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import axios from 'axios';

import CustomSnackbar from './Components/CustomSnackbar';
import DialogContentText from '@mui/material/DialogContentText';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../Store/auth';

function OrderManagement() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [orders, setOrders] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [isEmpty, setEmpty] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const { token } = useSelector((state) => state.loging);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [searchCategory, setSearchCategory] = useState('username');

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [newStatus, setNewStatus] = useState('');
  const [orderstutschange, setorderstutschange] = useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [orderIdToDelete, setOrderIdToDelete] = useState(null);

  const [severity, setseverity] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbarerror, setOpenSnackbarerror] = useState(false);

  const getStatusChip = (status) => {
    let color = '#FF0000'; // Red color for unknown status
    if (status === 'Shipping') color = '#1976D2'; // Blue
    else if (status === 'Pending') color = '#FFA500'; // Orange
    else if (status === 'Delivered') color = '#4CAF50'; // Green
    else if (status === 'Processing') color = '#9C27B0'; // Purple
    return color; // Return the color value
  };

  const getStatusLabel = (status) => {
    let label = 'Unknown'; // Default label for unknown status
    if (
      status === 'Shipping' ||
      status === 'Pending' ||
      status === 'Delivered' ||
      status === 'Processing'
    ) {
      label = status;
    }
    return label; // Return the label
  };

  const fetchOrders = async () => {
    axios
      .get(
        `${process.env.REACT_APP_APIURL}api/ordersWithAdmin?page=${currentPage}&limit=5&search=${searchQuery}&category=${searchCategory}`,
        {
          headers: { Authorization: 'Agriuservalidation ' + token },
        }
      )
      .then((res) => {
        if (res.data && res.data.orders && res.data.orders.length > 0) {
          setOrders(res.data.orders);
          setTotalPages(res.data.totalPages);
          setLoaded(true);
          setEmpty(true);
        } else {
          setLoaded(true);
          setEmpty(false);
          setSnackbarSeverity('error');
          setSnackbarMessage(
            'Error: An error occurred while fetching orders. Please try again later.'
          );
          setSnackbarOpen(true);
        }
      })
      .catch((error) => {
        setLoaded(true);
        setEmpty(false);
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbarerror(true);

            setTimeout(() => {
              dispatch(logout());
              navigate('/login');
            }, 7000);
          } else {
            setseverity('error');
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbarerror(true);
          }
        } else if (error.request) {
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbarerror(true);
        } else {
          setseverity('error');
          setMessage(`Error: ${error.message}`);
          setOpenSnackbarerror(true);
        }
      });
  };

  useEffect(() => {
    fetchOrders();
  }, [currentPage, searchQuery, searchCategory, token, orderstutschange]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSearchCategory(event.target.value);
  };

  const handleEditOrder = (order) => {
    setSelectedOrder(order);
    setNewStatus(order.tracking_status);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedOrder(null);
  };

  const handleStatusChange = (event) => {
    setNewStatus(event.target.value);
  };

  const handleUpdateOrderStatus = () => {
    axios
      .put(
        `${process.env.REACT_APP_APIURL}api/ordersWithAdmin/${selectedOrder._id}`,
        { newStatus },
        { headers: { Authorization: 'Bearer ' + token } }
      )
      .then((response) => {
        setSnackbarMessage(
          'Order status updated successfully. Email notification sent to the customer.'
        );
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        fetchOrders();
      })
      .catch((error) => {
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbarerror(true);

            setTimeout(() => {
              dispatch(logout());
              navigate('/login');
            }, 7000);
          } else {
            setseverity('error');
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbarerror(true);
          }
        } else if (error.request) {
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbarerror(true);
        } else {
          setseverity('error');
          setMessage(`Error: ${error.message}`);
          setOpenSnackbarerror(true);
        }
      });

    handleCloseDialog();
  };

  const handleDeleteOrder = (orderId) => {
    setOrderIdToDelete(orderId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setOrderIdToDelete(null);
  };

  const handleConfirmDelete = () => {
    axios
      .delete(
        `${process.env.REACT_APP_APIURL}api/ordersWithAdmin/${orderIdToDelete}`,
        {
          headers: { Authorization: 'Bearer ' + token },
        }
      )
      .then((response) => {
        setSnackbarMessage('Order deleted successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setOrders(orders.filter((order) => order._id !== orderIdToDelete));
      })
      .catch((error) => {
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbarerror(true);

            setTimeout(() => {
              dispatch(logout());
              navigate('/login');
            }, 7000);
          } else {
            setseverity('error');
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbarerror(true);
          }
        } else if (error.request) {
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbarerror(true);
        } else {
          setseverity('error');
          setMessage(`Error: ${error.message}`);
          setOpenSnackbarerror(true);
        }
      });

    handleCloseDeleteDialog();
  };

  const renderCustomerDetails = (customer) => {
    return `
      Username: ${customer.username} ,
      Email: ${customer.email} ,
      Mobile Number: ${customer.mobile_number} ,
      Address:
        Street: ${customer.address.address},
        City: ${customer.address.city},
        Province: ${customer.address.province},
        Postal Code: ${customer.address.postalcode},
        Country: ${customer.address.country},
    `;
  };

  const renderProductDetails = (products) => {
    return products.map((product, index) => (
      <div key={product._id}>
        {index + 1}. {product.title} - ${product.price} (Qty: {product.quantity}
        )
      </div>
    ));
  };

  return (
    <Grid container spacing={isMobile ? 1 : 2} p={isMobile ? 2 : 3}>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />

      <Grid
        item
        xs={12}
        mb={2}
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <FormControl
          variant='outlined'
          sx={{ width: isMobile ? '100%' : '20%' }}
        >
          <InputLabel id='search-category-label'>Search By</InputLabel>
          <Select
            labelId='search-category-label'
            value={searchCategory}
            onChange={handleCategoryChange}
            label='Search By'
          >
            <MenuItem value='username'>Customer Username</MenuItem>
            <MenuItem value='email'>Customer Email</MenuItem>
            <MenuItem value='productTitle'>Product Title</MenuItem>
            <MenuItem value='orderStatus'>Order Status</MenuItem>
            {/* <MenuItem value='Processing'>Processing</MenuItem>
            <MenuItem value='Shipping'>Shipping</MenuItem>
            <MenuItem value='Delivered'>Delivered</MenuItem>
            <MenuItem value='Pending'>Pending</MenuItem> */}
          </Select>
        </FormControl>
        <TextField
          label={`Search ${searchCategory}`}
          variant='outlined'
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{ ml: isMobile ? 0 : 2, mt: isMobile ? 2 : 0, flexGrow: 1 }}
        />
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: isMobile ? 300 : 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Customer Details</TableCell>
              <TableCell>Product Details</TableCell>
              <TableCell>Order Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {isEmpty && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography align='center'>No Orders found</Typography>
                  </TableCell>
                </TableRow>
              )}
              {!isEmpty &&
                isLoaded &&
                orders.map((order) => (
                  <TableRow key={order._id}>
                    <TableCell>{order._id}</TableCell>
                    <TableCell>
                      {renderCustomerDetails(order.vendordetails)}
                    </TableCell>
                    <TableCell>
                      {renderProductDetails(order.productsdetails)}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={getStatusLabel(order.tracking_status)}
                        style={{
                          backgroundColor: getStatusChip(order.tracking_status),
                          color: 'white',
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEditOrder(order)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteOrder(order._id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              {!isLoaded && (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Skeleton animation='wave' height={60} />
                    <Skeleton animation='wave' height={60} />
                    <Skeleton animation='wave' height={60} />
                    <Skeleton animation='wave' height={60} />
                    <Skeleton animation='wave' height={60} />
                  </TableCell>
                </TableRow>
              )}
            </>
          </TableBody>
        </Table>
        <Grid container justifyContent='center' p={2}>
          <Button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            Previous
          </Button>
          <Typography>{`Page ${currentPage} of ${totalPages}`}</Typography>
          <Button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next
          </Button>
        </Grid>

        <Dialog
          fullWidth={isMobile || isTablet}
          maxWidth='sm'
          open={openDialog}
          onClose={handleCloseDialog}
        >
          <DialogTitle>Edit Order Status</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel id='order-status-label'>Order Status</InputLabel>
              <Select
                labelId='order-status-label'
                value={newStatus}
                label='Order Status'
                onChange={handleStatusChange}
              >
                <MenuItem value='Processing'>Processing</MenuItem>
                <MenuItem value='Shipping'>Shipping</MenuItem>
                <MenuItem value='Delivered'>Delivered</MenuItem>
                <MenuItem value='Pending'>Pending</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleUpdateOrderStatus}>Update</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth={isMobile || isTablet}
          maxWidth='sm'
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
        >
          <DialogTitle>{'Delete Order'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this order?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
            <Button onClick={handleConfirmDelete}>Delete</Button>
          </DialogActions>
        </Dialog>
      </TableContainer>
    </Grid>
  );
}

export default OrderManagement;
