import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import AddressForm from "./AddressForm";

import Header from "../../Components/Header";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import OrderComformation from "./OrderComformation";
import CustomSnackbar from "../Adminpages/Components/CustomSnackbar";

const steps = ["Shipping address", "Order comfromation"];

export default function Checkout(props) {
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const { ID } = useParams();
  const { userID, token } = useSelector((state) => state.loging);
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <AddressForm id={ID} handleNext={handleNext} />;

      case 1:
        return (
          <OrderComformation
            id={ID}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  return (
    <>
      <Header token={token} />
      <CustomSnackbar open={open} message={message} severity={severity} />
      <Box component={Paper} py={0.1} square elevation={1} minHeight="83vh">
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography
              component="h1"
              variant="h3"
              align="center"
              sx={{ color: "#232F3E", fontFamily: "open sans" }}
            >
              Checkout
            </Typography>
            <Stepper
              activeStep={activeStep}
              sx={{ my: 3, py: 3, border: "2px solid #232F3E" }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <>
              {activeStep === steps.length ? (
                <>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ color: "#232F3E" }}
                  >
                    Thank you for your order.
                  </Typography>
                  <Typography variant="subtitle1" sx={{ color: "#232F3E" }}>
                    Your order number is{" "}
                    <span style={{ color: "#FF9933" }}>#{ID}</span>. We have
                    emailed your order confirmation, and will send you an update
                    when your order has shipped.
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button
                      variant="outlined"
                      href="/"
                      sx={{ color: "#FF9933" }}
                    >
                      Keep Shoping
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  {getStepContent(activeStep)}
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  ></Box>
                </>
              )}
            </>
          </Paper>
        </Container>
      </Box>
    </>
  );
}
