import React, { useState, useEffect } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Button,
  Grid,
  TextField,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  InputAdornment,
  Divider,
  Typography,
  Avatar,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../Store/auth";
import axios from "axios";

import logodestop from "../Assets/OfficalLog.png";
import logomobile from "../Assets/Icon_only.png";
import CategoryIcon from "@mui/icons-material/Category";
import { useMediaQuery } from "@mui/material";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { removeVendorID } from "../Store/VendorIDSlice";
import { StorefrontRounded } from "@mui/icons-material";

function Header(props) {
  const favoritesCount = useSelector((state) => state.favorites.count); // Accessing the favorites count from the Redux store
  const { itemCount: cartCount } = useSelector((state) => state.cart);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileSearch, setMobileSearch] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, role, userID } = useSelector((state) => state.loging);
  const [auth, setAuth] = useState(props.token ? true : false);
  const [cart, setCart] = useState(0);
  const [favorite, setFavorite] = useState(0);
  const [test, setTest] = useState(0);

  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [vendorDetails, setVendorDetails] = useState({});
  const vendorID = useSelector((state) => state.vendorID.vendorID);
  const formatAddress = (address) => {
    if (!address) {
      return "";
    }
    return `${address.address}, ${address.city}, ${address.province}, ${address.country}, ${address.postalcode}`;
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_APIURL}api/Category/Forcustomer/`, {
        headers: { Authorization: "Agriuservalidation " + token },
      })
      .then((response) => {
        setCategories(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the categories", error);
        setLoading(false);
      });
  }, [token]);

  useEffect(() => {
    if (role === "client") {
      axios
        .get(`${process.env.REACT_APP_APIURL}users/carts?_id=${userID}`, {
          headers: { Authorization: "Agriuservalidation " + token },
        })
        .then((res) => {
          setCart(res.data.length);
        });

      axios
        .get(`${process.env.REACT_APP_APIURL}users/favorites?_id=${userID}`, {
          headers: { Authorization: "Agriuservalidation " + token },
        })
        .then((res) => {
          setFavorite(res.data.length);
        });
    }
    // setTest(props.totalItems)
  }, [userID, token, role]);

  useEffect(() => {
    if (vendorID) {
      axios
        .get(
          `${process.env.REACT_APP_APIURL}users/withouttoken-user?ID=${vendorID}`
        )
        .then((res) => {
          console.log(res.data);
          setVendorDetails(res.data);
        })
        .catch((error) => {
          console.error("Error fetching vendor data: ", error);
        });
    }
  }, [vendorID]);

  const toggleMobileMenu = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setMobileMenuOpen(open);
  };

  const handleMobileSearch = () => {
    props.searchHandler(mobileSearch);
    setMobileMenuOpen(false);
  };

  const handleSearch = () => {
    props.searchHandler(search);
  };

  const [search, setSearch] = useState("");
  const handleMobileSearchIconClick = () => {
    props.searchHandler(mobileSearch);
  };

  const MobileMenuDrawer = (
    <Box
      sx={{ width: 250, backgroundColor: "#232F3E" }}
      role="presentation"
      onClick={toggleMobileMenu(false)}
      onKeyDown={toggleMobileMenu(false)}
    >
      <List>
        {!auth ? (
          <>
            <ListItem
              button
              onClick={() => navigate("/")}
              sx={{ display: { xs: "flex", md: "none" }, color: "#fff", py: 1 }}
            >
              <ListItemIcon>
                <img alt="logo" src={logomobile} style={{ width: 40 }} />
              </ListItemIcon>
              <ListItemText
                primary="Vender Mart"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 900, // Adjusted to the maximum typical font weight
                  letterSpacing: 7,
                  fontSize: 20,
                  color: "#FF9933",
                }}
              />
            </ListItem>
            <Divider sx={{ backgroundColor: "#FFFFFF" }} />
            <ListItem button component="a" href={"/login"}>
              <ListItemIcon>
                <AccountCircle sx={{ color: "#FF9933" }} />
              </ListItemIcon>
              <ListItemText primary="Log In" />
            </ListItem>
            <ListItem button component="a" href="/signup">
              <ListItemIcon>
                <AccountCircle sx={{ color: "#FF9933" }} />
              </ListItemIcon>
              <ListItemText primary="Sign Up" />
            </ListItem>

            <Box
              sx={{
                textAlign: "center",
                position: "fixed",
                bottom: 0,
                width: "250px",
              }}
            >
              {" "}
              {vendorID ? (
                <Card sx={{ margin: 2, backgroundColor: "#fff" }}>
                  <CardContent>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{ mb: 1, textAlign: "center" }}
                    >
                      {"Vendor Details"}
                    </Typography>
                    <Avatar
                      variant="square"
                      src={
                        vendorDetails.images
                          ? vendorDetails.images
                          : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                      }
                      sx={{ width: 100, height: 100, mx: "auto" }}
                    />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ textAlign: "center" }}
                    >
                      {vendorDetails.username}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ textAlign: "center" }}
                    >
                      {vendorDetails.mobile_number}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ textAlign: "center" }}
                    >
                      {vendorDetails.email}
                    </Typography>
                  </CardContent>
                </Card>
              ) : null}
            </Box>
          </>
        ) : (
          <>
            <ListItem
              button
              onClick={() => navigate("/")}
              sx={{ display: { xs: "flex", md: "none" }, color: "#fff", py: 1 }}
            >
              <ListItemIcon>
                <img alt="logo" src={logomobile} style={{ width: 40 }} />
              </ListItemIcon>
              <ListItemText
                primary="Vender Mart"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 900, // Adjusted to the maximum typical font weight
                  letterSpacing: 7,
                  fontSize: 20,
                  color: "#FF9933",
                }}
              />
            </ListItem>

            {role === "client" && (
              <>
                <ListItem button onClick={() => navigate("/favorites")}>
                  <ListItemIcon>
                    <FavoriteIcon sx={{ color: "#FF9933" }} />
                  </ListItemIcon>
                  <ListItemText primary="Your Wishlist" />
                </ListItem>
                <ListItem button onClick={() => navigate("/categorys")}>
                  <ListItemIcon>
                    <CategoryIcon sx={{ color: "#FF9933" }} />
                  </ListItemIcon>
                  <ListItemText primary="category" />
                </ListItem>
              </>
            )}

            <ListItem button onClick={() => navigate("/profile")}>
              <ListItemIcon>
                <PersonIcon sx={{ color: "#FF9933" }} />
              </ListItemIcon>
              <ListItemText primary="Your Account" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                dispatch(removeVendorID());
                dispatch(logout());
                setAuth(null);
                console.log("logout");
                window.location.href = "https://vendormart.lk/";
              }}
            >
              <ListItemIcon>
                <LogoutIcon sx={{ color: "#FF9933" }} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
            <Box
              sx={{
                textAlign: "center",
                position: "fixed",
                bottom: 0,
                width: "250px",
              }}
            >
              {" "}
              {vendorID ? (
                <Card sx={{ margin: 2, backgroundColor: "#fff" }}>
                  <CardContent>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{ mb: 1, textAlign: "center" }}
                    >
                      {"Vendor Details"}
                    </Typography>
                    <Avatar
                      variant="square"
                      src={
                        vendorDetails.images
                          ? vendorDetails.images
                          : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                      }
                      sx={{ width: 100, height: 100, mx: "auto" }}
                    />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ textAlign: "center" }}
                    >
                      {vendorDetails.username}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ textAlign: "center" }}
                    >
                      {vendorDetails.mobile_number}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ textAlign: "center" }}
                    >
                      {vendorDetails.email}
                    </Typography>
                  </CardContent>
                </Card>
              ) : null}
            </Box>
          </>
        )}
      </List>
    </Box>
  );

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="sticky"
          // sx={{ bgcolor: role === "client" ? "#232F3E" : "transparent" }}
          sx={{ bgcolor: "#232F3E" }}
          elevation={0}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleMobileMenu(true)}
              sx={{ mr: 2, display: { sm: "block", md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "block" } }}>
              {/* {role === "client" && ( */}
              <Button href="/" sx={{ marginLeft: 1 }}>
                <img
                  alt="logo"
                  src={logodestop}
                  style={{ width: 200, height: 35 }}
                />
                <Box pl={2} />
              </Button>
              {/* )} */}
            </Box>

            {auth && (
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={9}
                  md={10}
                  lg={8}
                  container
                  sx={{
                    margin: isMobile ? 0.6 : 0,

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* {role !== "admin" && ( */}
                  {role === "client" && (
                    <TextField
                      onKeyUp={handleSearch}
                      value={search}
                      onChange={(event) => setSearch(event.target.value)}
                      required
                      margin="dense"
                      size="small"
                      id="search"
                      placeholder="Search..."
                      name="search"
                      autoComplete="search"
                      fullWidth
                      autoFocus
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleSearch}
                              sx={{
                                "&:hover": {
                                  color: "#FF9933",
                                },
                              }}
                              edge="end"
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        bgcolor: "#fff", // Background color of the search bar
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor:
                              role === "Vendor" ? "#232F3E" : "transparent", // Remove border
                          },
                          "&:hover fieldset": {
                            borderColor: "#FF9933", // Border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#FF9933", // Border color when focused
                          },
                        },
                        borderRadius: 1, // Rounded corne$
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            )}

            {!auth && (
              <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
                {/* ... User profile and other icons for desktop view ... */}
                {vendorID && (
                  <Tooltip
                    title={
                      <Card
                        sx={{
                          bgcolor: "#F5F5F5",
                          boxShadow: 3,
                          borderRadius: 2,
                          p: 2,
                          maxWidth: 250, // Adjust the width as needed
                        }}
                      >
                        <CardContent>
                          <Typography
                            variant="body1"
                            color="text.secondary"
                            sx={{ mb: 1, textAlign: "center" }}
                          >
                            {"Vendor Details"}
                          </Typography>
                          <Avatar
                            variant="square"
                            src={
                              vendorDetails.images
                                ? vendorDetails.images
                                : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                            }
                            sx={{ width: 100, height: 100, mx: "auto" }}
                          />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ textAlign: "center" }}
                          >
                            {vendorDetails.username}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ textAlign: "center" }}
                          >
                            {vendorDetails.mobile_number}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ textAlign: "center" }}
                          >
                            {vendorDetails.email}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ textAlign: "center" }}
                          >
                            {formatAddress(vendorDetails.address)}
                          </Typography>
                        </CardContent>
                      </Card>
                    }
                    open={tooltipOpen}
                    sx={{
                      display: { xs: "none", md: "flex" },
                      color: "#FFFFFF",
                      "& .MuiTooltip-tooltip": {
                        // This targets the tooltip content
                        backgroundColor: "#FFFFFF",
                        color: "#FFFFFF",
                        // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                      },
                      "& .MuiTooltip-arrow": {
                        // This targets the tooltip arrow
                        color: "#FFFFFF",
                      },
                    }}
                    onClose={() => setTooltipOpen(false)}
                    disableFocusListener
                    disableTouchListener
                    PopperProps={{
                      onMouseEnter: () => setTooltipOpen(true),
                      onMouseLeave: () => setTooltipOpen(false),
                    }}
                  >
                    <IconButton
                      onMouseEnter={() => setTooltipOpen(true)}
                      onMouseLeave={() => setTooltipOpen(false)}
                      onClick={() => {}}
                      sx={{
                        display: { xs: "none", md: "flex" },
                        "&:hover": { bgcolor: "#FEC260" },
                        mr: 2,
                        borderRadius: 2,
                        p: 1,
                      }}
                    >
                      <StorefrontRounded sx={{ color: "#fff" }} />
                    </IconButton>
                  </Tooltip>
                )}

                <Button href="/login" key={"login"} sx={{ color: "#eee" }}>
                  Log In
                </Button>
                <Button href="/signup" key={"signup"} sx={{ color: "#eee" }}>
                  Sign up
                </Button>
              </Box>
            )}

            {auth && (
              <Box sx={{ flexGrow: 0, display: { md: "flex" } }}>
                {vendorID && (
                  <Tooltip
                    title={
                      <Card
                        sx={{
                          bgcolor: "#F5F5F5",
                          boxShadow: 3,
                          borderRadius: 2,
                          p: 2,
                          maxWidth: 250, // Adjust the width as needed
                        }}
                      >
                        <CardContent>
                          <Typography
                            variant="body1"
                            color="text.secondary"
                            sx={{ mb: 1, textAlign: "center" }}
                          >
                            {"Vendor Details"}
                          </Typography>
                          <Avatar
                            variant="square"
                            src={
                              vendorDetails.images
                                ? vendorDetails.images
                                : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                            }
                            sx={{ width: 100, height: 100, mx: "auto" }}
                          />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ textAlign: "center" }}
                          >
                            {vendorDetails.username}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ textAlign: "center" }}
                          >
                            {vendorDetails.mobile_number}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ textAlign: "center" }}
                          >
                            {vendorDetails.email}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ textAlign: "center" }}
                          >
                            {formatAddress(vendorDetails.address)}
                          </Typography>
                        </CardContent>
                      </Card>
                    }
                    open={tooltipOpen}
                    sx={{
                      display: { xs: "none", md: "flex" },
                      color: "#FFFFFF",
                      "& .MuiTooltip-tooltip": {
                        // This targets the tooltip content
                        backgroundColor: "#FFFFFF",
                        color: "#FFFFFF",
                        // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                      },
                      "& .MuiTooltip-arrow": {
                        // This targets the tooltip arrow
                        color: "#FFFFFF",
                      },
                    }}
                    onClose={() => setTooltipOpen(false)}
                    disableFocusListener
                    disableTouchListener
                    PopperProps={{
                      onMouseEnter: () => setTooltipOpen(true),
                      onMouseLeave: () => setTooltipOpen(false),
                    }}
                  >
                    <IconButton
                      onMouseEnter={() => setTooltipOpen(true)}
                      onMouseLeave={() => setTooltipOpen(false)}
                      onClick={() => {}}
                      sx={{
                        display: { xs: "none", md: "flex" },
                        "&:hover": { bgcolor: "#FEC260" },
                        mr: 2,
                        borderRadius: 2,
                        p: 1,
                      }}
                    >
                      <StorefrontRounded sx={{ color: "#fff" }} />
                    </IconButton>
                  </Tooltip>
                )}

                {role === "client" && (
                  <Tooltip title="Your Cart">
                    <IconButton
                      onClick={() => {
                        navigate("/cart");
                      }}
                      sx={{
                        display: { xs: "flex", md: "flex" }, // Show on both mobile and desktop
                        "&:hover": { bgcolor: "#FEC260" },
                        mr: 2,
                      }}
                    >
                      <Badge showZero color="error" badgeContent={cartCount}>
                        <ShoppingCartIcon sx={{ color: "#fff" }} />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                )}
                {role === "client" && (
                  <Tooltip title="Your Wishlist">
                    <IconButton
                      sx={{
                        display: { xs: "none", md: "flex" }, // Hide on mobile, show on desktop
                        "&:hover": { bgcolor: "#FEC260" },
                        mr: 2,
                      }}
                      onClick={() => {
                        navigate("/favorites");
                      }}
                    >
                      <Badge
                        showZero
                        color="error"
                        badgeContent={favoritesCount}
                      >
                        <FavoriteIcon sx={{ color: "#fff" }} />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                )}

                <Tooltip title="Your Account">
                  <IconButton
                    onClick={() => {
                      navigate("/profile");
                    }}
                    sx={{
                      bgcolor: role === "client" ? "transparent" : "#232F3E",

                      display: { xs: "none", md: "flex" }, // Hide on mobile, show on desktop
                      "&:hover": { bgcolor: "#FEC260" },
                      mr: 2,
                    }}
                  >
                    <PersonIcon sx={{ color: "#fff" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Logout">
                  <IconButton
                    sx={{
                      bgcolor: role === "client" ? "transparent" : "#232F3E",
                      // color: role === "client" ? "#232F3E" : "#fff",
                      display: { xs: "none", md: "flex" }, // Hide on mobile, show on desktop
                      "&:hover": { bgcolor: "#FEC260" },
                      mr: 2,
                    }}
                    onClick={() => {
                      dispatch(removeVendorID());
                      dispatch(logout());
                      setAuth(null);
                      console.log("logout");
                      window.location.href = "https://vendormart.lk/";
                    }}
                  >
                    <LogoutIcon sx={{ color: "#fff" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer
        anchor="left"
        open={mobileMenuOpen}
        onClose={toggleMobileMenu(false)}
        PaperProps={{
          sx: {
            color: "#fff",
            bgcolor: "#232F3E",
          },
        }}
      >
        {MobileMenuDrawer}
      </Drawer>
    </>
  );
}

export default Header;
