// new code above ###################################################################################################################

import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axios from "axios";
import LocationViewDialogBox from "./LocationViewDialogBox";
import { Container, colors } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import useMediaQuery from "@mui/material";

const cities = [
  "All of Sri Lanka",
  "Colombo",
  "Kandy",
  "Galle",
  "Matara",
  "Batticaloa",
  "Jaffna",
  "Gampaha",
  "Anuradhapura",
  "Trincomalee",
  "Badulla",
  "Ratnapura",
  "Puttalam",
  "Ampara",
  "Matale",
  "Vavuniya",
  "Mulaittivu",
  "Kalutara",
  "Mannar",
  "Kurunegala",
  "Polonnaruwa",
  "Nuwara Eliya",
  "Kilinochchi",
  "Kegalle",
  "Moneragala",
  "Hambantota",
];

const areas = {
  "All of Sri Lanka": ["All of Sri Lanka"],
  Colombo: ["All Colombo"],
  Kandy: ["All Kandy"],
  Galle: ["All Galle"],
  Matara: ["All Matara"],
  Batticaloa: ["All Batticaloa"],
  Jaffna: ["All Jaffna"],
  Gampaha: ["All Gampaha"],
  Anuradhapura: ["All Anuradhapura"],
  Trincomalee: ["All Trincomalee"],
  Badulla: ["All Badulla"],
  Ratnapura: ["All Ratnapura"],
  Puttalam: ["All Puttalam"],
  Ampara: ["All Ampara"],
  Matale: ["All Matale"],
  Vavuniya: ["All Vavuniya"],
  Mulaittivu: ["All Mullaitivu"],
  Kalutara: ["All Kalutara"],
  Mannar: ["All Mannar"],
  Kurunegala: ["All Kurunegala"],
  Polonnaruwa: ["All Polonnaruwa"],
  NuwaraEliya: ["All Nuwara Eliya"],
  Kilinochchi: ["All Kilinochchi"],
  Kegalle: ["All Kegalle"],
  Moneragala: ["All Moneragala"],
  Hambantota: ["All Hambantota"],
  // ... add more if needed
};

const CategorySelection = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedArea, setSelectedArea] = useState("All of Sri Lanka");
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false); // State to manage loading status
  const navigate = useNavigate(); // Hook for navigating

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true); // Start loading
      try {
        let response = await axios.post(
          `${process.env.REACT_APP_APIURL}users/ForCustomer/Vendors`,
          { locations: areas }
        );
        if (response.status === 200) {
          let newCategories = [];
          const data = response.data;

          if (selectedArea === "All of Sri Lanka") {
            newCategories = Object.values(data).reduce((acc, curr) => {
              Object.entries(curr).forEach(([category, value]) => {
                const existingCategory = acc.find(
                  (item) => item.categoryName === category
                );
                if (existingCategory) {
                  // Only add vendors that are not already included
                  value.vendors.forEach((vendor) => {
                    if (
                      !existingCategory.vendors.some((v) => v.id === vendor.id)
                    ) {
                      existingCategory.vendors.push(vendor);
                    }
                  });
                } else {
                  acc.push({
                    categoryName: category,
                    vendors: value.vendors,
                    categoryImage: value.categoryimage,
                  });
                }
              });
              return acc;
            }, []);
          } else {
            newCategories = Object.entries(data[selectedArea] || {}).map(
              ([category, value]) => ({
                categoryName: category,
                vendors: value.vendors,
                categoryImage: value.categoryimage,
              })
            );
          }
          setCategories(newCategories);

          console.log(newCategories);
          setLoading(false); // Start loading
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [selectedArea]);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleAreaSelect = (area) => {
    setSelectedArea(area);
    handleCloseDialog();
  };
  const handleCategoryClick = (categoryName, vendors) => {
    console.log(categoryName, vendors);
    navigate(`/location/category/${categoryName}`, { state: { vendors } });
  };

  return (
    <>
      <Header />

      <Box sx={{ width: "100%", p: 2, minHeight: "90vh" }}>
        <Container
          maxWidth="md"
          sx={{ p: 2, display: "flex", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              mb: 2,
            }}
            onClick={handleOpenDialog}
          >
            Select Area
          </Button>

          <LocationViewDialogBox
            open={isDialogOpen}
            onClose={handleCloseDialog}
            onSelectArea={handleAreaSelect}
          />
        </Container>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ textAlign: "center" }}
        >
          Browse items by category
        </Typography>

        {loading ? (
          <CircularProgress
            sx={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        ) : categories.length > 0 ? (
          <Container
            maxWidth="md"
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container spacing={2}>
              {categories.map((category, index) => (
                <Grid item xs={6} sm={4} md={3} lg={3} key={index}>
                  <Grid
                    onClick={() =>
                      handleCategoryClick(
                        category.categoryName,
                        category.vendors
                      )
                    }
                    sx={{
                      cursor: "pointer",
                      padding: "10px",
                      textAlign: "center",

                      borderRadius: "5px",
                      marginBottom: "10px",

                      "&:hover": {
                        color: "black",
                        bgcolor: "white",
                        // border: "1px solid #ccc",
                        marginBottom: "10px",
                        borderRadius: "5px",
                        marginRight: "17px",
                        // transform: "scale(1.05)",
                      },
                    }}
                  >
                    <img
                      src={category?.categoryImage}
                      style={{
                        width: "120px",
                        height: "120px",
                        objectFit: "cover",
                        borderRadius: "10px",
                        marginBottom: "10px",
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      sx={{
                        color: "black",
                        fontWeight: "bold",
                        marginBottom: "10px",
                        textAlign: "left",
                      }}
                    >
                      {category?.categoryName}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Container>
        ) : (
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              textAlign: "center",
              mt: 6,

              color: "gray",

              fontWeight: "bold",
            }}
          >
            No categories found in this location.
          </Typography>
        )}
      </Box>
      <Footer />
    </>
  );
};

export default CategorySelection;
