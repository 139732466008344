import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Box,
  Grid,
  Link,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Avatar,
  Container,
  Stack,
  FormHelperText,
  ImageList,
  ImageListItem,
} from "@mui/material";

import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../../Components/Header";
import AgriSnakbar from "../../Utils/AgriSnackbar";
import { login } from "../../../Store/auth";
import Alert from "../../../Components/Alert";
import CustomButton from "../../../Components/CustomButton";
import CustomTextField from "../../../Components/CustomTextField";
import logo from "../../../Assets/logo1.png";
import CustomSnackbar from "../../Adminpages/Components/CustomSnackbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { red } from "@mui/material/colors";
// import signuppageimage from "../../../Assets/SignUPpage.png";
// import signuppageimage from "../../../Assets/signupimage2.png";
import signuppageimage from "../../../Assets/logoforimage3.jpg";
import officallogo from "../../../Assets/OfficalLog.png";
import { useMediaQuery, useTheme } from "@mui/material";

export default function SignUp(props) {
  const locations = {
    "All of Sri Lanka": ["All of Sri Lanka"],
    Colombo: ["Colombo"],
    Kandy: ["Kandy"],
    Galle: ["Galle"],
    Matara: ["Matara"],
    Batticaloa: ["Batticaloa"],
    Jaffna: ["Jaffna"],
    Gampaha: ["Gampaha"],
    Anuradhapura: ["Anuradhapura"],
    Trincomalee: ["Trincomalee"],
    Badulla: ["Badulla"],
    Ratnapura: ["Ratnapura"],
    Puttalam: ["Puttalam"],
    Ampara: ["Ampara"],
    Matale: ["Matale"],
    Vavuniya: ["Vavuniya"],
    Mulaittivu: ["Mullaitivu"],
    Kalutara: ["Kalutara"],
    Mannar: ["Mannar"],
    Kurunegala: ["Kurunegala"],
    Polonnaruwa: ["Polonnaruwa"],
    NuwaraEliya: ["Nuwara Eliya"],
    Kilinochchi: ["Kilinochchi"],
    Kegalle: ["Kegalle"],
    Moneragala: ["Moneragala"],
    Hambantota: ["Hambantota"],
  };

  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [role, setRole] = useState("client");
  const [opensanackbar, setOpensnackbar] = useState(false);
  const [snackmessage, setSnackmessage] = useState("");
  const [snackseverity, setSnackseverity] = useState("success");
  const [errors, setErrors] = useState({});

  const [categories, setCategories] = useState([]);

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // Combine address-related states into a single state object
  const [addressData, setAddressData] = useState({
    address: "",
    city: "",
    province: "",
    country: "",
    postalcode: "",
  });

  const { address, city, province, country, postalcode } = addressData;

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeHandler = () => {
    setOpen(false);
  };

  // useEffect to log updated addressData after each re-render
  useEffect(() => {
    console.log("Updated addressData:", addressData);
  }, [addressData]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APIURL}api/Category/ForVendors/`)
      .then((res) => {
        console.log(res.data.data);
        const categoryData = res.data.data.map((category) => ({
          name: category.categoryname,
          image: category.categoryimages, // Assuming image URL is returned here
          id: category._id,
        }));
        setCategories(categoryData);
      })
      .catch((err) => {
        console.log("error: " + err);
        // setError(err.response.data.message);
      });
  }, []);

  const validateForm = () => {
    let tempErrors = {};
    // Add validation for each field like username, email, password, etc.
    tempErrors.username = username ? "" : "Username is required.";
    tempErrors.email = email && email.includes("@") ? "" : "Invalid Email.";
    tempErrors.password =
      password.length >= 6
        ? ""
        : "Password must be at least 6 characters long.";
    tempErrors.mobile =
      mobile && mobile.length === 10
        ? ""
        : "Invalid Mobile Number. It must be 10 digits.";

    tempErrors.address = address ? "" : "Address is required.";
    tempErrors.city = city ? "" : "City is required.";
    tempErrors.province = province ? "" : "Province is required.";
    tempErrors.country = country ? "" : "Country is required.";
    tempErrors.postalcode = postalcode ? "" : "Postalcode is required.";
    // Add more validations as per your form fields

    if (role === "Vendor" && selectedLocations.length === 0) {
      tempErrors.selectedLocations =
        "At least one location is required for Vendor.";
    }

    if (role === "Vendor" && selectedCategories.length === 0) {
      tempErrors.selectedCategories =
        "At least one category is required for Vendor.";
    }

    setErrors({ ...tempErrors });

    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = (event) => {
    console.log(mobile.length);
    event.preventDefault();
    if (!validateForm()) {
      setError("Please correct the errors before submitting.");
      setOpen(true); // Assuming 'open' state is used to control the visibility of an error notification
      return;
    }

    // Validation for each field
    if (!username.trim()) {
      setError("Username is required");
      return;
    }

    if (!email.trim() || !email.includes("@") || !email.endsWith(".com")) {
      setError("Invalid Email ID");
      return;
    }

    if (!password.trim()) {
      setError("Password is required");
      return;
    }
    if (password.length < 6) {
      setError("Password must be at least 6 characters long");
      return;
    }

    if (!address.trim()) {
      setError("Address is required");
      return;
    }

    if (!city.trim()) {
      setError("City is required");
      return;
    }

    if (!country.trim()) {
      setError("Country is required");
      return;
    }

    if (!postalcode.trim()) {
      setError("Postalcode is required");
      return;
    }

    if (role === "Vendor" && selectedLocations.length === 0) {
      setError("At least one location is required for Vendor");
      return;
    }

    // Update the addressData state
    setAddressData({
      address: address,
      city: city,
      province: province,
      country: country,
      postalcode: postalcode,
    });
    // Map selected categories to the required format
    const formattedCategories = selectedCategories.map((category) => ({
      categoryname: category.name,
      categoryimage: category.image,
      _id: category.id,
    }));

    console.log("hello");
    console.log(addressData);

    axios
      .post(`${process.env.REACT_APP_APIURL}users`, {
        username: username,
        email: email,
        password: password,
        mobile_number: mobile,
        address: addressData,
        role: role,
        locations: selectedLocations,
        categories: formattedCategories,
      })
      .then((res) => {
        if (res.data.msg === "email already exist") {
          setOpensnackbar(true);
          setSnackmessage("User with this email already exists");
          setSnackseverity("warning");
        } else if (res.data.msg === "mobile number already exist") {
          setSnackmessage("User with this mobile number already exists");
          setSnackseverity("warning");
          setOpensnackbar(true);
        } else if (
          res.data.msg === "something went wrong while registering try again"
        ) {
          setSnackmessage("Something went wrong");
          setSnackseverity("error");
          setOpensnackbar(true);
        } else {
          if (res.data.type === "Vendor" && res.data.Approveled === "Blocked") {
            setSnackmessage(
              "Vendor Registration Successfully please wait for admin approval"
            );
            setSnackseverity("success");
            setOpensnackbar(true);
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          } else {
            setSnackmessage("User Registration Successfully");
            setSnackseverity("success");
            setOpensnackbar(true);

            setTimeout(() => {
              window.location.href = "https://vendormart.lk/";
              // navigate('/');
              // dispatch(
              //   login({
              //     role: res.data.type,
              //     userID: res.data._id,
              //     token: res.data.token,
              //   })
              // );
            }, 1000);
          }
        }
      })
      .catch((er) => {
        console.error(er);
        setSnackmessage("Check your internet connection and try again");
        setSnackseverity("error");
        setOpensnackbar(true);
      });
  };

  const handleClose = () => {
    setError("");
  };

  // Updated handleAddressChange function
  const handleAddressChange = (event) => {
    setAddressData({
      ...addressData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSelectChange = (event) => {
    if (event.target.name === "locations") {
      setSelectedLocations(event.target.value);
    } else if (event.target.name === "categories") {
      setSelectedCategories(event.target.value);
    }
  };

  return (
    <>
      <ToastContainer />
      {/* <Header /> */}
      <Alert
        handleClose={handleClose}
        title="Alert!"
        open={error}
        msg={error}
      />
      <CustomSnackbar
        open={opensanackbar}
        onClose={() => setOpensnackbar(false)}
        severity={snackseverity}
        message={snackmessage}
      />

      <Box
        minHeight={"100vh"}
        sx={{
          backgroundImage: `url("${signuppageimage}")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // maginRight: "226px !important",
        }}
      >
        <Container
          component={Paper}
          maxWidth={isMobile ? "xl" : "sm"}
          sx={{
            maginRight: "226px !important",
            mt: isMobile ? 0 : 3,
            mb: isMobile ? 0 : 3,
            borderRadius: 3,
            p: 3,
            bgcolor: "rgba(255, 255, 255, 0.8)",
            backdropFilter: "blur(7px)",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <img
              alt="logo"
              src={officallogo}
              style={{
                width: 190,
                height: 80,
                mb: 1,
                alignSelf: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          </Box>

          <Typography component="h1" variant="h3" textAlign={"center"}>
            Sign up
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  required
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                  fullWidth
                  id="username"
                  label="User Name"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  error={!!errors.username}
                  helperText={errors.username}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  required
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  required
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  error={!!errors.password}
                  helperText={errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="role-select-label">Role</InputLabel>
                  <Select
                    labelId="role-select-label"
                    id="role-select"
                    value={role}
                    onChange={handleChange}
                    error={!!errors.role}
                  >
                    <MenuItem value={"client"}>Client</MenuItem>
                    <MenuItem value={"Vendor"}>Vendor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  required
                  value={mobile}
                  onChange={(event) => setMobile(event.target.value)}
                  fullWidth
                  id="mobile-number"
                  label="Mobile Number"
                  name="mobile-number"
                  type="number"
                  error={!!errors.mobile}
                  helperText={errors.mobile}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  required
                  value={address}
                  onChange={handleAddressChange}
                  fullWidth
                  name="address"
                  label="Address"
                  error={!!errors.address}
                  helperText={errors.address}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  name="city"
                  required
                  value={city}
                  onChange={handleAddressChange}
                  fullWidth
                  label="City"
                  error={!!errors.city}
                  helperText={errors.city}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  name="province"
                  required
                  value={province}
                  onChange={handleAddressChange}
                  fullWidth
                  label="Province"
                  error={!!errors.province}
                  helperText={errors.province}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  name="country"
                  required
                  value={country}
                  onChange={handleAddressChange}
                  fullWidth
                  label="Country"
                  error={!!errors.country}
                  helperText={errors.country}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  name="postalcode"
                  required
                  value={postalcode}
                  onChange={handleAddressChange}
                  fullWidth
                  label="Postalcode"
                  error={!!errors.postalcode}
                  helperText={errors.postalcode}
                />
              </Grid>

              {/* {role === "Vendor" && (
                <Grid item xs={12}>
                  <FormControl fullWidth error={!!errors.selectedCategories}>
                    <InputLabel id="multi-category-select-label">
                      Categories
                    </InputLabel>
                    <Select
                      labelId="multi-category-select-label"
                      id="multi-category-select"
                      multiple
                      value={selectedCategories}
                      onChange={handleSelectChange}
                      renderValue={(selected) => (
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {selected.map((value) => (
                            <Avatar
                              alt={value?.categoryname}
                              src={value?.categoryimages}
                              style={{
                                marginRight: "5px",
                                marginBottom: "5px",
                                width: "24px",
                                height: "24px",
                              }}
                            />
                          ))}
                        </div>
                      )}
                    >
                      {categories.map((category) => (
                        <MenuItem
                          key={category?.id}
                          value={category}
                          style={{
                            backgroundColor: selectedCategories.some(
                              (c) => c._id === category._id
                            )
                              ? "#232F3E"
                              : "transparent",
                            color: selectedCategories.some(
                              (c) => c._id === category._id
                            )
                              ? "white"
                              : "inherit",
                          }}
                        >
                          {category.categoryname}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {!!errors.selectedCategories && errors.selectedCategories}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )} */}

              {role === "Vendor" && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="category-select-label">Category</InputLabel>
                    <Select
                      labelId="category-select-label"
                      id="category-select"
                      multiple
                      name="categories"
                      value={selectedCategories}
                      onChange={handleSelectChange}
                      renderValue={(selected) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          {selected.map((item) => (
                            <div
                              key={item.id}
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={item.image}
                                alt={item.name}
                                style={{ width: 30, marginRight: 10 }}
                              />
                              {item.name}
                            </div>
                          ))}
                        </div>
                      )}
                    >
                      {categories.map((category) => (
                        <MenuItem
                          key={category.id}
                          value={category}
                          style={{
                            backgroundColor: selectedCategories.includes(
                              category
                            )
                              ? "#232F3E"
                              : "transparent",
                            color: selectedCategories.includes(category)
                              ? "white"
                              : "inherit",
                          }}
                        >
                          <img
                            src={category.image}
                            alt={category.name}
                            style={{ width: 30, marginRight: 10 }}
                          />
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {/* {role === 'Vendor' && (
                <Grid item xs={12}>
                  <Stack direction='row' spacing={1} overflow='auto'>
                    <FormControl fullWidth>
                      <InputLabel id='multi-location-select-label'>
                        Locations
                      </InputLabel>
                      <Select
                        labelId='multi-location-select-label'
                        id='multi-location-select'
                        multiple
                        value={selectedLocations}
                        onChange={(e) => setSelectedLocations(e.target.value)}
                        renderValue={(selected) => (
                          <div>
                            {selected
                              .sort((a, b) => a.localeCompare(b))
                              .map((value) => (
                                <div key={value} style={{ margin: '5px 0' }}>
                                  {value}
                                </div>
                              ))}
                          </div>
                        )}
                      >
                        {Array.from(new Set(Object.values(locations).flat()))
                          .sort((a, b) => a.localeCompare(b))
                          .map((location) => (
                            <MenuItem
                              key={location}
                              value={location}
                              style={{
                                backgroundColor: selectedLocations.includes(
                                  location
                                )
                                  ? '#232F3E'
                                  : 'transparent',
                                color: selectedLocations.includes(location)
                                  ? 'white'
                                  : 'inherit',
                              }}
                            >
                              {location}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText sx={{ color: 'red' }}>
                        {errors.selectedLocations}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Grid>
              )} */}

              {role === "Vendor" && (
                <Grid item xs={12}>
                  <Stack direction="row" spacing={1} overflow="auto">
                    <FormControl fullWidth>
                      <InputLabel id="multi-location-select-label">
                        Locations
                      </InputLabel>
                      <Select
                        labelId="multi-location-select-label"
                        id="multi-location-select"
                        multiple
                        value={selectedLocations}
                        onChange={(e) => setSelectedLocations(e.target.value)}
                        renderValue={(selected) => (
                          <div>
                            {selected
                              .sort((a, b) => a.localeCompare(b))
                              .map((value) => (
                                <div key={value} style={{ margin: "5px 0" }}>
                                  {value}
                                </div>
                              ))}
                          </div>
                        )}
                      >
                        {Array.from(new Set(Object.values(locations).flat()))
                          .sort((a, b) => a.localeCompare(b))
                          .map((location) => (
                            <MenuItem
                              key={location}
                              value={location}
                              style={{
                                backgroundColor: selectedLocations.includes(
                                  location
                                )
                                  ? "#232F3E"
                                  : "transparent",
                                color: selectedLocations.includes(location)
                                  ? "white"
                                  : "inherit",
                              }}
                            >
                              {location}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText sx={{ color: "red" }}>
                        {errors.selectedLocations}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Grid>
              )}
            </Grid>
            <CustomButton title={"Sign Up"} />
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}
