import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../Store/auth';

const useErrorHandler = () => {
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleError = useCallback(
    (error) => {
      if (error.response) {
        const statusCode = error.response.status;
        console.log(statusCode, error.response.data);
        if (statusCode === 400) {
          setSeverity('error');
          setMessage('Token expired, please login again.');
          setOpenSnackbar(true);

          setTimeout(() => {
            console.log('Authentication failed. Logging out.');
            dispatch(logout());
            navigate('/login');
          }, 3000); // Adjust duration as needed
        } else {
          setSeverity('error');
          setMessage('An error occurred. Please try again later.');
          setOpenSnackbar(true);
        }
      } else if (error.request) {
        setSeverity('error');
        setMessage(
          'No response from server. Please check your network connection.'
        );
        setOpenSnackbar(true);
      } else {
        setSeverity('error');
        setMessage(`Error: ${error.message}`);
        setOpenSnackbar(true);
      }
    },
    [dispatch, navigate]
  );

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return {
    severity,
    message,
    openSnackbar,
    handleError,
    handleCloseSnackbar,
  };
};

export default useErrorHandler;
