import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Stack,
} from "@mui/material";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ImageModal from "../../Utils/ImageModal";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import AgriSnackbar from "../../Utils/AgriSnackbar";
import LoadingButton from "@mui/lab/LoadingButton";
import { login, logout } from "../../../Store/auth";
import Alert from "../../../Components/Alert";
import CustomButton from "../../../Components/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../Adminpages/Components/CustomSnackbar";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import React from "react";
function Account() {
  const locations = {
    "All of Sri Lanka": ["All of Sri Lanka"],
    Colombo: ["All Colombo"],
    Kandy: ["All Kandy"],
    Galle: ["All Galle"],
    Matara: ["All Matara"],
    Batticaloa: ["All Batticaloa"],
    Jaffna: ["All Jaffna"],
    Gampaha: ["All Gampaha"],
    Anuradhapura: ["All Anuradhapura"],
    Trincomalee: ["All Trincomalee"],
    Badulla: ["All Badulla"],
    Ratnapura: ["All Ratnapura"],
    Puttalam: ["All Puttalam"],
    Ampara: ["All Ampara"],
    Matale: ["All Matale"],
    Vavuniya: ["All Vavuniya"],
    Mulaittivu: ["All Mullaitivu"],
    Kalutara: ["All Kalutara"],
    Mannar: ["All Mannar"],
    Kurunegala: ["All Kurunegala"],
    Polonnaruwa: ["All Polonnaruwa"],
    NuwaraEliya: ["All Nuwara Eliya"],
    Kilinochchi: ["All Kilinochchi"],
    Kegalle: ["All Kegalle"],
    Moneragala: ["All Moneragala"],
    Hambantota: ["All Hambantota"],
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showReNewPassword, setShowReNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const [open, setOpen] = useState(false);
  const [Sopen, setSOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [btnDisable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [severity, setseverity] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  // form data
  const [username, setUserName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [ReNewPassword, setReNewPassword] = useState("");
  const [addressAll, setaddressAll] = useState({
    address: "",
    city: "",
    province: "",
    country: "",
    postalcode: "",
  });
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [country, setCountry] = useState("");
  const [postalcode, setPostalcode] = useState("");
  const [role, setRole] = useState("");
  const [dp, setDp] = useState("");
  const [error, seterror] = useState("");
  // error
  const [openerror, setopenerror] = useState(false);
  const [errormessage, seterrormessage] = useState("");
  const [errorseverity, seterrorseverity] = useState("");

  //sucess messge
  const [opensuccess, setopensuccess] = useState(false);
  const [sucessmessage, setsucessmessage] = useState("");
  const [successseverity, setsuccessseverity] = useState("");

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let tempErrors = {};
    // Username validation
    tempErrors.username = username ? "" : "Username is required.";

    // Email validation: simple regex to check for a basic email pattern
    tempErrors.email = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)
      ? ""
      : "Invalid email format.";

    tempErrors.mobile = /^(\d{10})$/.test(number)
      ? ""
      : "Invalid mobile number. It must be 10 digits.";

    // Address validation
    tempErrors.address = address ? "" : "Address is required.";

    // City validation
    tempErrors.city = city ? "" : "City is required.";

    // Country validation
    tempErrors.country = country ? "" : "Country is required.";

    // Postal code validation
    tempErrors.postalcode = postalcode ? "" : "Postal code is required.";

    // Locations validation (for users with a role of "Vendor")
    if (role === "Vendor" && selectedLocations.length === 0) {
      tempErrors.selectedLocations =
        "At least one location is required for Vendors.";
    }

    setErrors({ ...tempErrors });

    // Check if any errors were set, if not the form is valid
    return Object.values(tempErrors).every((x) => x === "");
  };

  const validateFormPassword = () => {
    let tempErrors = {};

    // Current Password validation
    tempErrors.password =
      password.length >= 6
        ? ""
        : "Password must be at least 6 characters long.";

    // New Password validation
    tempErrors.newPassword =
      newPassword.length >= 6
        ? ""
        : "New Password must be at least 6 characters long.";

    // Re-typed New Password validation
    if (!tempErrors.newPassword) {
      // Only compare if new password is valid
      tempErrors.ReNewPassword =
        newPassword === ReNewPassword ? "" : "Passwords did not match.";
    } else {
      tempErrors.ReNewPassword = "Please correct the new password first.";
    }

    setErrors({ ...tempErrors });

    // Check if any errors were set, if not the form is valid
    return Object.values(tempErrors).every((x) => x === "");
  };

  // user data
  const { token, userID } = useSelector((state) => state.loging);

  // handlers
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSClose = () => setSOpen(false);
  const handleSelectChange = (event) => {
    if (event.target.name === "locations") {
      setSelectedLocations(event.target.value);
    } else if (event.target.name === "categories") {
      setSelectedCategories(event.target.value);
    }
  };
  const handleAddressChange = (event) => {
    setaddressAll({
      address: address,
      city: city,
      province: province,
      country: country,
      postalcode: postalcode,
    });
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APIURL}api/Category/ForVendors/`)
      .then((res) => {
        console.log(res.data.data);
        const categoryData = res.data.data.map((category) => ({
          name: category.categoryname,
          image: category.categoryimages, // Assuming image URL is returned here
          id: category._id,
        }));
        setCategories(categoryData);
      })
      .catch((err) => {
        console.log("error: " + err);
        // setError(err.response.data.message);
      });
  }, []);
  // useEffect call
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APIURL}users?ID=${userID}`, {
        headers: { Authorization: "Agriuservalidation " + token },
      })
      .then((res) => {
        setUserName(res.data.username);
        setEmail(res.data.email);
        setNumber(res.data.mobile_number);
        setRole(res.data.role);
        setDp(res.data.images);
        setaddressAll(res.data.address);
        setAddress(res.data.address.address);
        setCity(res.data.address.city);
        setCountry(res.data.address.country);
        setProvince(res.data.address.province);
        setPostalcode(res.data.address.postalcode);
        // Set selected locations if the user is a Vendor
        if (res.data.role === "Vendor" && res.data.locations) {
          setSelectedLocations(res.data.locations);
        }
        if (res.data.role === "Vendor" && res.data.categories) {
          const categoryData = res.data.categories.map((category) => ({
            name: category.categoryname,
            image: category.categoryimage, // Assuming image URL is returned here
            id: category._id,
          }));
          console.log(categoryData);
          setSelectedCategories(categoryData);
        }
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setseverity("error");
            setMessage("Token expired, please login again.");
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              console.log("Authentication failed. Logging out.");
              dispatch(logout());
              navigate("/login");
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other errors
            // setseverity('error');
            // setMessage('An error occurred. Please try again later.');
            // setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity("error");
          setMessage(
            "No response from server. Please check your network connection."
          );
          setOpenSnackbar(true);
        } else {
          setseverity("error");
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  }, []);

  // update details
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      seterrorseverity("error");
      seterrormessage("Please correct the errors before submitting.");
      setopenerror(true); // Assuming 'open' state is used to control the visibility of an error notification
      return;
    }
    const formattedCategories = selectedCategories.map((category) => ({
      categoryname: category.name,
      categoryimage: category.image,
      _id: category.id,
    }));
    console.log(formattedCategories);
    setDisable(true);
    console.log(selectedLocations);
    axios
      .put(
        `${process.env.REACT_APP_APIURL}users`,
        {
          username: username,
          email: email,
          address: addressAll,
          mobile_number: number,
          _id: userID,
          locations: role === "Vendor" ? selectedLocations : undefined,
          categories: formattedCategories,
        },
        {
          headers: { Authorization: "Agriuservalidation " + token },
        }
      )
      .then((res) => {
        setopensuccess(true);
        setsuccessseverity("success");
        setsucessmessage("Profile Updated Successfully");
        setDisable(false);

        // Delay the reload to briefly show the message
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setseverity("error");
            setMessage("Token expired, please login again.");
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              console.log("Authentication failed. Logging out.");
              dispatch(logout());
              navigate("/login");
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other errors
            // setseverity('error');
            // setMessage('An error occurred. Please try again later.');
            // setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity("error");
          setMessage(
            "No response from server. Please check your network connection."
          );
          setOpenSnackbar(true);
        } else {
          setseverity("error");
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  };

  // change password
  const handleSubmitPassword = (event) => {
    event.preventDefault();
    if (!validateFormPassword()) {
      seterrorseverity("error");
      seterrormessage("Please correct the errors before submitting.");
      setopenerror(true); // Assuming 'open' state is used to control the visibility of an error notification
      return;
    }
    setDisable(true);
    axios
      .put(
        `${process.env.REACT_APP_APIURL}users`,
        { password, newPassword, _id: userID },
        {
          headers: { Authorization: "Agriuservalidation " + token },
        }
      )
      .then((res) => {
        if (res.data.updated === "password not match") {
          setopensuccess(true);
          setsuccessseverity("error");
          setsucessmessage(
            "your enter current Password not match. please enter correct password"
          );
          setDisable(false);
        } else {
          setopensuccess(true);
          setsuccessseverity("success");
          setsucessmessage("Password Updated Successfully");
          setPassword("");
          setNewPassword("");
          setReNewPassword("");
          setDisable(false);

          // Delay the reload to briefly show the message
          setTimeout(() => {
            window.location.reload();
          }, 1000); // Adjust the delay as needed
        }
      })
      .catch(() => {
        if (error.response) {
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setseverity("error");
            setMessage("Token expired, please login again.");
            setOpenSnackbar(true);

            setTimeout(() => {
              console.log("Authentication failed. Logging out.");
              dispatch(logout());
              navigate("/login");
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other errors
            // setseverity('error');
            // setMessage('An error occurred. Please try again later.');
            // setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity("error");
          setMessage(
            "No response from server. Please check your network connection."
          );
          setOpenSnackbar(true);
        } else {
          setseverity("error");
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  };

  // close Alert
  const handleCloseAlert = () => {
    setopenerror(false);
  };

  return (
    <>
      <Alert
        open={openerror}
        handleClose={handleCloseAlert}
        msg={errormessage}
        title="Alert!"
      />
      {/* <AgriSnackbar open={Sopen} handler={handleSClose} msg={msg} /> */}
      <CustomSnackbar
        open={openSnackbar}
        onClose={() => {
          setOpenSnackbar(false);
        }}
        message={message}
        severity={severity}
      />

      <CustomSnackbar
        open={opensuccess}
        onClose={() => {
          setopensuccess(false);
        }}
        message={sucessmessage}
        severity={successseverity}
      />
      <ImageModal
        userID={userID}
        token={token}
        open={open}
        handleClose={handleClose}
      />
      <Box p={2} m={0} component={Paper} elevation={2} square>
        <Container maxWidth="sm">
          <Box
            pb={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <Tooltip title="change image">
                  <IconButton
                    onClick={handleOpen}
                    sx={{
                      bgcolor: "#232F3E",
                      color: "#fff",
                      "&:hover": { bgcolor: "#232F3E", color: "#000" },
                    }}
                  >
                    <CollectionsOutlinedIcon />
                  </IconButton>
                </Tooltip>
              }
            >
              <Avatar
                src={dp}
                sx={{ height: 150, width: 150, border: "1px solid #333" }}
              >
                <PersonOutlineOutlinedIcon />
              </Avatar>
            </Badge>
          </Box>
          <Box component="form" sx={{ mt: 3 }} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  contentEditable={false}
                  fullWidth
                  inputProps={{ sx: { color: "#232F3E" } }}
                  value={username}
                  id="username"
                  label="User Name"
                  name="username"
                  autoComplete="username"
                  type={"text"}
                  onChange={(event) => {
                    setUserName(event.target.value);
                  }}
                  error={!!errors.username}
                  helperText={errors.username}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  contentEditable={false}
                  fullWidth
                  inputProps={{ sx: { color: "#232F3E" } }}
                  value={email}
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  type={"email"}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ sx: { color: "#232F3E" } }}
                  value={number}
                  onChange={(event) => {
                    setNumber(event.target.value);
                  }}
                  id="mobile-number"
                  label="Mobile Number"
                  name="mobile-number"
                  type="number"
                  autoComplete="mobile-number"
                  error={!!errors.mobile}
                  helperText={errors.mobile}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ sx: { color: "#232F3E" } }}
                  value={address}
                  onChange={(event) => {
                    setAddress(event.target.value);
                    handleAddressChange();
                  }}
                  name="address"
                  label="Address"
                  id="address"
                  autoComplete="address"
                  error={!!errors.address}
                  helperText={errors.address}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="city"
                  inputProps={{ sx: { color: "#232F3E" } }}
                  fullWidth
                  value={city}
                  onChange={(event) => {
                    setCity(event.target.value);
                    handleAddressChange();
                  }}
                  id="city"
                  label="City"
                  error={!!errors.city}
                  helperText={errors.city}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  inputProps={{ sx: { color: "#232F3E" } }}
                  value={province}
                  onChange={(event) => {
                    setProvince(event.target.value);
                    handleAddressChange();
                  }}
                  id="province"
                  label="Province"
                  name="province"
                  autoComplete="province"
                  error={!!errors.province}
                  helperText={errors.province}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="country"
                  name="country"
                  fullWidth
                  value={country}
                  onChange={(event) => {
                    setCountry(event.target.value);
                    handleAddressChange();
                  }}
                  id="country"
                  label="Country"
                  autoFocus
                  inputProps={{ sx: { color: "#232F3E" } }}
                  error={!!errors.country}
                  helperText={errors.country}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  inputProps={{ sx: { color: "#232F3E" } }}
                  value={postalcode}
                  onChange={(event) => {
                    setPostalcode(event.target.value);
                    handleAddressChange();
                  }}
                  id="postalcode"
                  label="Postalcode"
                  name="postalcode"
                  autoComplete="postalcode"
                  error={!!errors.postalcode}
                  helperText={errors.postalcode}
                />
              </Grid>
              {role === "Vendor" && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="category-select-label">Category</InputLabel>
                    <Select
                      labelId="category-select-label"
                      id="category-select"
                      multiple
                      name="categories"
                      value={selectedCategories}
                      onChange={handleSelectChange}
                      renderValue={(selected) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          {selected.map((item) => (
                            <div
                              key={item.id}
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={item.image}
                                alt={item.name}
                                style={{ width: 30, marginRight: 10 }}
                              />
                              {item.name}
                            </div>
                          ))}
                        </div>
                      )}
                    >
                      {categories.map((category) => (
                        <MenuItem
                          key={category.id}
                          value={category}
                          style={{
                            backgroundColor: selectedCategories.includes(
                              category
                            )
                              ? "#232F3E"
                              : "transparent",
                            color: selectedCategories.includes(category)
                              ? "white"
                              : "inherit",
                          }}
                        >
                          <img
                            src={category.image}
                            alt={category.name}
                            style={{ width: 30, marginRight: 10 }}
                          />
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {/* {role === 'Vendor' && (
                <Grid item xs={12}>
                  <Stack direction='row' spacing={1} overflow='auto'>
                    <FormControl fullWidth>
                      <InputLabel id='multi-location-select-label'>
                        Locations
                      </InputLabel>
                      <Select
                        labelId='multi-location-select-label'
                        id='multi-location-select'
                        multiple
                        value={selectedLocations}
                        onChange={(e) => setSelectedLocations(e.target.value)}
                        renderValue={(selected) => (
                          <div>
                            {selected
                              .sort((a, b) => a.localeCompare(b))
                              .map((value) => (
                                <div key={value} style={{ margin: '5px 0' }}>
                                  {value}
                                </div>
                              ))}
                          </div>
                        )}
                      >
                        {Object.values(locations)
                          .flat()
                          .sort((a, b) => a.localeCompare(b))
                          .map((location) => (
                            <MenuItem
                              key={location}
                              value={location}
                              style={{
                                backgroundColor: selectedLocations.includes(
                                  location
                                )
                                  ? '#232F3E'
                                  : 'transparent',
                                color: selectedLocations.includes(location)
                                  ? 'white'
                                  : 'inherit',
                              }}
                            >
                              {location}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText sx={{ color: 'red' }}>
                        {errors.selectedLocations}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Grid>
              )} */}

              {/* {role === 'Vendor' && (
                <Grid item xs={12}>
                  <Stack direction='row' spacing={1} overflow='auto'>
                    <FormControl fullWidth>
                      <InputLabel id='multi-location-select-label'>
                        Locations
                      </InputLabel>
                      <Select
                        labelId='multi-location-select-label'
                        id='multi-location-select'
                        multiple
                        value={selectedLocations}
                        onChange={(e) => setSelectedLocations(e.target.value)}
                        renderValue={(selected) => (
                          <div>
                            {selected
                              .sort((a, b) => a.localeCompare(b))
                              .map((value) => (
                                <div key={value} style={{ margin: '5px 0' }}>
                                  {value}
                                </div>
                              ))}
                          </div>
                        )}
                      >
                        {Object.keys(locations).map((district) =>
                          locations[district].map((location) => (
                            <MenuItem
                              key={location}
                              value={location}
                              style={{
                                backgroundColor: selectedLocations.includes(
                                  location
                                )
                                  ? '#232F3E'
                                  : 'transparent',
                                color: selectedLocations.includes(location)
                                  ? 'white'
                                  : 'inherit',
                              }}
                            >
                              {location}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                      <FormHelperText sx={{ color: 'red' }}>
                        {errors.selectedLocations}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Grid>
              )} */}
              {/* 
              {role === 'Vendor' && (
                <Grid item xs={12}>
                  <Stack direction='row' spacing={1} overflow='auto'>
                    <FormControl fullWidth>
                      <InputLabel id='multi-location-select-label'>
                        Locations
                      </InputLabel>
                      <Select
                        labelId='multi-location-select-label'
                        id='multi-location-select'
                        multiple
                        value={selectedLocations}
                        onChange={(e) => setSelectedLocations(e.target.value)}
                        renderValue={(selected) => (
                          <div>
                            {selected
                              .sort((a, b) => a.localeCompare(b))
                              .map((value) => (
                                <div key={value} style={{ margin: '5px 0' }}>
                                  {value}
                                </div>
                              ))}
                          </div>
                        )}
                      >
                        {Object.keys(locations)
                          .sort((a, b) => a.localeCompare(b))
                          .map((district) => (
                        
                            <React.Fragment key={district}>
                              {locations[district]
                                .sort((a, b) => a.localeCompare(b))
                                .map((location) => (
                                  <MenuItem
                                    key={location}
                                    value={location}
                                    style={{
                                      backgroundColor:
                                        selectedLocations.includes(location)
                                          ? '#232F3E'
                                          : 'transparent',
                                      color: selectedLocations.includes(
                                        location
                                      )
                                        ? 'white'
                                        : 'inherit',
                                    }}
                                  >
                                    {location}
                                  </MenuItem>
                                ))}
                            </React.Fragment>
                          ))}
                      </Select>
                      <FormHelperText sx={{ color: 'red' }}>
                        {errors.selectedLocations}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Grid>
              )} */}

              {role === "Vendor" && (
                <Grid item xs={12}>
                  <Stack direction="row" spacing={1} overflow="auto">
                    <FormControl fullWidth>
                      <InputLabel id="multi-location-select-label">
                        Locations
                      </InputLabel>
                      <Select
                        labelId="multi-location-select-label"
                        id="multi-location-select"
                        multiple
                        value={selectedLocations}
                        onChange={(e) => setSelectedLocations(e.target.value)}
                        renderValue={(selected) => (
                          <div>
                            {selected
                              .sort((a, b) => a.localeCompare(b))
                              .map((value) => (
                                <div key={value} style={{ margin: "5px 0" }}>
                                  {value}
                                </div>
                              ))}
                          </div>
                        )}
                      >
                        {Array.from(new Set(Object.values(locations).flat()))
                          .sort((a, b) => a.localeCompare(b))
                          .map((location) => (
                            <MenuItem
                              key={location}
                              value={location}
                              style={{
                                backgroundColor: selectedLocations.includes(
                                  location
                                )
                                  ? "#232F3E"
                                  : "transparent",
                                color: selectedLocations.includes(location)
                                  ? "white"
                                  : "inherit",
                              }}
                            >
                              {location}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText sx={{ color: "red" }}>
                        {errors.selectedLocations}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Grid>
              )}
            </Grid>
            <CustomButton title={"Save Changes"} disabled={btnDisable} />
          </Box>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmitPassword}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={password}
                  type={showCurrentPassword ? "text" : "password"}
                  onChange={(event) => setPassword(event.target.value)}
                  name="current-password"
                  label="current password"
                  id="current-password"
                  autoComplete="current-password"
                  error={!!errors.password}
                  helperText={
                    errors.password
                      ? "Password must be at least 6 characters long."
                      : ""
                  }
                  InputProps={{
                    sx: { color: "#232F3E" },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle current password visibility"
                          onClick={() =>
                            setShowCurrentPassword(!showCurrentPassword)
                          }
                          edge="end"
                        >
                          {showCurrentPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={newPassword}
                  type={showPassword ? "text" : "password"}
                  onChange={(event) => setNewPassword(event.target.value)}
                  name="new-password"
                  label="new password"
                  id="new-password"
                  autoComplete="new-password"
                  error={!!errors.newPassword}
                  helperText={errors.newPassword}
                  InputProps={{
                    sx: { color: "#232F3E" },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={ReNewPassword}
                  type={showReNewPassword ? "text" : "password"}
                  onChange={(event) => setReNewPassword(event.target.value)}
                  name="re-new-password" // Changed name for clarity
                  label="re-type password"
                  id="rnew-password"
                  autoComplete="new-password"
                  error={!!errors.ReNewPassword}
                  helperText={errors.ReNewPassword}
                  InputProps={{
                    sx: { color: "#232F3E" },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle re-new password visibility"
                          onClick={() =>
                            setShowReNewPassword(!showReNewPassword)
                          }
                          edge="end"
                        >
                          {showReNewPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <CustomButton
              title="Change Password"
              onClick={handleSubmitPassword}
              disabled={btnDisable}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Account;
