import * as React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';
import axios from 'axios';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { logout } from '../../Store/auth';
import { decreaseStock, increaseStock, setStock } from '../../Store/stockSlice';
import { useEffect } from 'react';
export default function AgriCart(props) {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [val, setVal] = useState(props.quantity ? props.quantity : 1);
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [UpdaetStatus, setUpdaetStatus] = useState('');

  const { token, role, userID } = useSelector((state) => state.loging);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorSnackbar(false);
  };
  const productID = props.data._id;

  useEffect(() => {
    console.log('Stock:', props.stock, props.data._id);
    dispatch(setStock({ productID, stock: props.stock }));
  }, [props.stock, props.data._id, dispatch]);
  const stock = useSelector((state) => state.stock.stocks[productID] || 0); // Default to 0 if undefined
  console.log('Stock:', stock, productID);

  const updateStock = (newStock, productId) => {
    axios
      .put(
        `${process.env.REACT_APP_APIURL}api/updateStock/${productId}/?UpdaetStatus=${UpdaetStatus} `,
        { stocks: newStock },
        {
          headers: { Authorization: 'Agriuservalidation ' + token },
        }
      )
      .then((response) => {
        console.log('Stock updated successfully:', response.data);
      })
      .catch((error) => {
        if (error.response) {
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setSeverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);
            setTimeout(() => {
              dispatch(logout());
              navigate('/login');
            }, 7000);
          } else {
            setSeverity('error');
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          setSeverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setSeverity('error');
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  };

  const incVal = () => {
    if (val < props.stock) {
      dispatch(decreaseStock({ productID }));
      setUpdaetStatus('incs');
      const newStock = props.stock - 1;
      updateStock(props.stock, props.data._id);
      setVal((pre) => pre + 1);
      props.quantityHandler(
        'inc',
        +props.data.price,
        props.data._id,
        val,
        newStock
      );
    } else {
      setOpenErrorSnackbar(true);
    }
  };

  const decVal = () => {
    if (val > 1 && val <= props.stock) {
      setUpdaetStatus('deces');
      dispatch(increaseStock({ productID }));
      const newStock = props.stock + 1;
      updateStock(props.stock, props.data._id);
      setVal((pre) => pre - 1);
      props.quantityHandler(
        'dec',
        +props.data.price,
        props.data._id,
        val,
        newStock
      );
    }
  };

  return (
    <Card
      sx={{
        display: isMobile ? 'wrap' : 'flex',
        my: 3,
        boxShadow: '0 1px 3px #232F3E, opacity(0.4)',
        borderRadius: '8px',
        padding: 2,
        justifyContent: 'space-between',
      }}
    >
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity='error'
          sx={{ width: '100%' }}
        >
          Not enough stock available!
        </MuiAlert>
      </Snackbar>
      <CardMedia
        component='img'
        sx={{ width: '27%', height: '25%', objectFit: 'cover', mx: 1, my: 1 }}
        image={props.data.images[0]}
        alt='img'
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '2 0 auto', textAlign: 'left' }}>
          <Typography
            fontFamily='open sans'
            fontWeight='bold'
            component='div'
            variant='subtitle1'
            sx={{ color: '#232F3E' }}
          >
            {`Rs ${props.data.price}`}
          </Typography>
          <Typography
            variant='body2'
            color='text.secondary'
            component='div'
            fontFamily='open sans'
            fontWeight={isMobile ? '' : 'bold'}
            sx={{ mt: 1, color: '#232F3E' }}
          >
            {props.data.title}
          </Typography>
          <Typography
            variant='subtitle2'
            component='div'
            fontFamily='open sans'
            sx={{
              mt: 1,
              color: '#232F3E',
              textTransform: 'capitalize',
              fontSize: '12px !important',
            }}
          >
            Available Stock: {stock}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
          <IconButton
            onClick={decVal}
            sx={{
              bgcolor: '#aaa',
              borderRadius: 1,
              mr: 3,
              ml: 1,
              '&:hover': { bgcolor: '#FF9933', color: '#fff' },
            }}
          >
            <RemoveOutlinedIcon />
          </IconButton>
          <Typography>{val}</Typography>
          <IconButton
            onClick={incVal}
            sx={{
              bgcolor: '#aaa',
              borderRadius: 1,
              ml: 3,
              '&:hover': { bgcolor: '#FF9933', color: '#fff' },
            }}
          >
            <AddOutlinedIcon />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box p={0.5} sx={{ display: 'flex', justifyContent: 'right' }}>
        <IconButton
          onClick={() => props.removeCart(props.index, props.data._id)}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
    </Card>
  );
}
