import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Button, Box, Typography, Grid, Paper, Container } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logout } from '../../Store/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomSnackbar from '../Adminpages/Components/CustomSnackbar';
function OrderConfirmation(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [shippingAddress, setShippingAddress] = useState({});
  const { userID, token } = useSelector((state) => state.loging);

  const [severity, setseverity] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleSubmit = () => {
    axios
      .patch(
        `${process.env.REACT_APP_APIURL}users/carts?_id=${userID}`,
        {},
        {
          headers: { Authorization: 'Agriuservalidation ' + token },
        }
      )
      .then((res) => {
        props.handleNext();
        setTimeout(() => {
          toast('payment is success', { type: 'success' });
        }, 2000);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              console.log('Authentication failed. Logging out.');
              dispatch(logout());
              navigate('/login');
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other errors
            setseverity('error');
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setseverity('error');
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  };

  const handleBack = () => {
    props.handleBack();
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await axios.get(
          `${process.env.REACT_APP_APIURL}users?ID=${userID}`,
          {
            headers: { Authorization: 'Agriuservalidation ' + token },
          }
        );
        setUserDetails(userResponse.data);

        console.log(props.id);

        const orderResponse = await axios.get(
          `${process.env.REACT_APP_APIURL}api/orders/address/get?_id=${props.id}`,
          {
            headers: { Authorization: 'Agriuservalidation ' + token },
          }
        );
        console.log(orderResponse.data);
        setShippingAddress(orderResponse.data.address);
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              console.log('Authentication failed. Logging out.');
              dispatch(logout());
              navigate('/login');
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other errors
            setseverity('error');
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setseverity('error');
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      }
    };

    fetchData();
  }, [userID, token, props.id]);

  return (
    <>
      <ToastContainer />
      <CustomSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        severity={severity}
        message={message}
      />
      <Container>
        <Typography variant='h4' align='center' gutterBottom color='#232F3E'>
          Order Confirmation
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h6'>User Details</Typography>
            <Typography>{userDetails.username}</Typography>
            <Typography>{userDetails.email}</Typography>
            <Typography>{userDetails.mobile_number}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant='h6'>Shipping Address Details</Typography>
            <Typography>{shippingAddress.address}</Typography>
            <Typography>{shippingAddress.city}</Typography>
            {/* <Typography>{shippingAddress.province}</Typography> */}
            <Typography>{shippingAddress.postalcode}</Typography>
            <Typography>{shippingAddress.country}</Typography>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button
            variant='contained'
            onClick={handleBack}
            sx={{
              color: '#fff',
              backgroundColor: '#FF9933',
              '&:hover': { backgroundColor: '#FF9933', color: '#000' },
            }}
          >
            Back
          </Button>

          <Button
            variant='contained'
            onClick={handleSubmit}
            sx={{
              color: '#fff',
              backgroundColor: '#FF9933',
              '&:hover': { bgcolor: '#FF9933', color: '#000' },
            }}
          >
            Next
          </Button>
        </Box>
      </Container>
    </>
  );
}

export default OrderConfirmation;
